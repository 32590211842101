// Angular
import {Injectable} from '@angular/core';
// 3rd-party
import {Observable} from 'rxjs';
import {map} from "rxjs/operators";
// core
import {ApiService} from 'src/app/core/services';
// shared
import {BankAccount, SimpleBankAccount, SortOrder} from 'src/app/shared';
import {UpdateBankAccount} from "../../../shared/models/update-bank-account";
// local
import {
  AiarAuthorizationRequest,
  AiarCreationRequest,
  AiarInfoForBankAccountRequest,
  AiarInfoForFinancialInstitutionRequest,
  ArchiveAccountRequest,
  BankAccountDetailsRequest,
  CountBankAccountsRequest,
  DisableAccountRequest,
  DownloadTransactionsForAccountRequest,
  ListArchivedBankAccountsRequest,
  ListBankAccountsRequest,
  ListFinancialInstitutionsRequest,
  ListTransactionsForAccountRequest,
  RestoreAccountRequest,
  UnlinkClientRequest,
  UpdateBankAccountSettingsRequest
} from '../api-requests';
import {
  AiarAuthorizationDTO,
  AiarAuthorizationResponse,
  AiarCreation,
  AiarCreationResponse,
  AiarInfo,
  BankAccountsBrowseRequest,
  BankAccountsResult,
  FinancialInstitution,
  LinkedStatus,
  Transaction,
  TransactionsForAccountBrowseRequest,
  TransactionsResult
} from '../models';
import {ListUnlinkedBankAccountsRequest} from "../api-requests/list-unlinked-bank-accounts.request";
import {UpdateTransactionRequestForAccountRequest} from "../api-requests/update-transaction-request-for-account.request";
import {UpdateTransactionCategory} from "../models/update-transaction-category";
import {ListAllPaymentBankAccountsRequest} from "../api-requests/list-all-payment-bank-accounts.request";
import {TransactionsExport} from '../utils/transactions-export';
import {UpdateTransactionTags} from '../models/update-transaction-tags.model';
import {UpdateTransactionTagsRequest} from '../api-requests/update-transaction-tags.request';

@Injectable({providedIn: 'root'})
export class AccountsService {
  constructor(private apiService: ApiService) {
  }

  getAvailableFinancialInstitutions(onlyUsed: boolean = false): Observable<FinancialInstitution[]> {
    return this.apiService.execute<FinancialInstitution[]>(new ListFinancialInstitutionsRequest(onlyUsed));
  }

  getAiarInfoForFinancialInstitution(financialInstitutionId: string): Observable<AiarInfo> {
    return this.apiService.execute<AiarInfo>(new AiarInfoForFinancialInstitutionRequest(financialInstitutionId));
  }

  getAiarInfoForBankAccount(bankAccountId: string): Observable<AiarInfo> {
    return this.apiService.execute<AiarInfo>(new AiarInfoForBankAccountRequest(bankAccountId));
  }

  createAiar(aiarCreation: AiarCreation, forImport: boolean): Observable<AiarCreationResponse> {
    return this.apiService.execute<AiarCreationResponse>(new AiarCreationRequest(aiarCreation, forImport));
  }

  authorizeAiar(aiarAuthorizationDTO: AiarAuthorizationDTO): Observable<AiarAuthorizationResponse> {
    return this.apiService.execute<AiarAuthorizationResponse>(new AiarAuthorizationRequest(aiarAuthorizationDTO));
  }

  browseAccounts(bankAccountsBrowseRequest: BankAccountsBrowseRequest): Observable<BankAccountsResult> {
    return this.apiService.execute<BankAccountsResult>(new ListBankAccountsRequest(bankAccountsBrowseRequest));
  }

  getAllPaymentBankAccounts(forClientId?: string): Observable<SimpleBankAccount[]> {
    return this.apiService.execute<SimpleBankAccount[]>(new ListAllPaymentBankAccountsRequest(forClientId));
  }

  getUnlinkedAccounts(): Observable<BankAccount[]> {
    return this.apiService.execute<BankAccount[]>(new ListUnlinkedBankAccountsRequest());
  }

  browseArchivedAccounts(bankAccountsBrowseRequest: BankAccountsBrowseRequest): Observable<BankAccountsResult> {
    return this.apiService.execute<BankAccountsResult>(new ListArchivedBankAccountsRequest(bankAccountsBrowseRequest));
  }

  getAccountDetails(bankAccountId: string): Observable<BankAccount> {
    return this.apiService.execute<BankAccount>(new BankAccountDetailsRequest(bankAccountId));
  }

  getTransactionsForAccount(
    bankAccountId: string,
    transactionsForAccountBrowseRequest: TransactionsForAccountBrowseRequest
  ): Observable<TransactionsResult> {
    return this.apiService.execute<TransactionsResult>(
      new ListTransactionsForAccountRequest(bankAccountId, transactionsForAccountBrowseRequest)
    );
  }

  updateTransactionCategoryForAccount(
    bankAccountId: string, transactionId: string, request: UpdateTransactionCategory
  ): Observable<Transaction> {
    return this.apiService.execute<Transaction>(
      new UpdateTransactionRequestForAccountRequest(bankAccountId, transactionId, request)
    );
  }

  updateTransactionTags(bankAccountId: string, transactionId: string, request: UpdateTransactionTags): Observable<Transaction> {
    return this.apiService.execute<Transaction>(
      new UpdateTransactionTagsRequest(bankAccountId, transactionId, request)
    );
  }

  downloadTransactionsForAccount(
    bankAccountId: string,
    transactionsForAccountBrowseRequest: TransactionsForAccountBrowseRequest,
    type: TransactionsExport.Type
  ): Observable<Blob> {
    return this.apiService.execute<Blob>(
      new DownloadTransactionsForAccountRequest(bankAccountId, transactionsForAccountBrowseRequest, type)
    );
  }

  disableBankAccount(bankAccountId: string): Observable<BankAccount> {
    return this.apiService.execute<BankAccount>(new DisableAccountRequest(bankAccountId));
  }

  unlinkClient(bankAccountId: string, clientId: string): Observable<BankAccount> {
    return this.apiService.execute<BankAccount>(new UnlinkClientRequest(bankAccountId, clientId));
  }

  countBankAccounts(onlyWithWarning: boolean): Observable<number> {
    return this.apiService.execute<number>(new CountBankAccountsRequest(onlyWithWarning));
  }

  countUnlinkedBankAccounts(): Observable<number> {
    return this.browseAccounts({
      currentPage: 1,
      pageSize: 1,
      financialInstitutionId: 'ALL',
      linkedStatus: LinkedStatus.UNLINKED,
      sortColumn: 'label',
      sortDirection: SortOrder.ASC
    }).pipe(map((result) => result.resultsCount));
  }

  updateAccountsSettings(bankAccountId: string, updateBankAccount: UpdateBankAccount): Observable<BankAccount> {
    return this.apiService.execute<BankAccount>(
      new UpdateBankAccountSettingsRequest(bankAccountId, updateBankAccount)
    );
  }

  archive(bankAccountId: string): Observable<void> {
    return this.apiService.execute<void>(new ArchiveAccountRequest(bankAccountId));
  }

  restoreBankAccount(bankAccountId: string): Observable<void> {
    return this.apiService.execute<void>(new RestoreAccountRequest(bankAccountId));
  }
}
