// core
import { APIRequest, Method } from 'src/app/core/models';

export class DisableAccountRequest implements APIRequest {
  method: Method = Method.PUT;
  path: string;

  constructor(bankAccountId: string) {
    this.path = '/bank-accounts/' + bankAccountId + '/disable';
  }
}
