// Angular
import { Injectable } from '@angular/core';
// 3rd-party
import { TranslateService } from '@ngx-translate/core';
// shared
import { AppSettings, LanguageCode } from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  constructor(private translateService: TranslateService) {}

  setLanguages() {
    this.translateService.addLangs(Object.values(LanguageCode));
  }

  setTranslationCode(code?: LanguageCode): void {
    let locale: string | null | undefined = code;

    if (locale) {
      this.updateLocalInStorage(locale);
    } else {
      locale = this.localeFromStorage();
    }

    this.translateService.use(this.validLanguageCode(locale));
  }

  getTranslationCode(): string {
    let locale = this.localeFromStorage();
    return this.validLanguageCode(locale);
  }

  translate(code: string): string {
    return this.translateService.instant(code);
  }

  private localeFromStorage(): string | null {
    return window.localStorage.getItem(AppSettings.LOCAL_STORAGE_LOCALE);
  }

  private updateLocalInStorage(locale: string) : void {
    window.localStorage.setItem(AppSettings.LOCAL_STORAGE_LOCALE, locale);
  }

  private validLanguageCode(locale: string | null): string {
    let validLocale = locale;
    if( !validLocale ){
      // use locale from ngx-translate instead
      validLocale = this.translateService.currentLang;
    }
    if( !validLocale ){
      // fallback to EN as default
      validLocale = LanguageCode.EN as string;
    }
    return validLocale.toLowerCase()
  }

}
