// core
import { APIRequest, Method } from 'src/app/core/models';
// local
import { AiarAuthorizationDTO } from '../models';

export class AiarAuthorizationRequest implements APIRequest {
  method: Method = Method.POST;
  path: string = '/bank-accounts/aiar/authorization';
  body: AiarAuthorizationDTO;

  constructor(aiarAuthorizationDTO: AiarAuthorizationDTO) {
    this.body = aiarAuthorizationDTO;
  }
}
