// Angular
import { Injectable } from '@angular/core';
// 3rd-party
import { Observable } from 'rxjs';
// core
import { ApiService } from 'src/app/core/services';
// local
import {
  OfficeUser,
  PasswordUpdateDTO,
  UserDetailDTO
} from '../models';
import {
  UpdateUserRequest,
  UpdatePasswordRequest,
  UserDetailRequest,
} from '../api-requests';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  constructor(private apiService: ApiService) {}

  // TODO: Replace with dedicated endpoint for getting active user
  getProfile(userId: string): Observable<OfficeUser> {
    return this.apiService.execute<OfficeUser>(new UserDetailRequest(userId));
  }

  // TODO: Replace with dedicated endpoint for updating active user
  updateProfile(userDetailDTO: UserDetailDTO, userId: string): Observable<OfficeUser> {
    return this.apiService.execute<OfficeUser>(new UpdateUserRequest(userDetailDTO, userId));
  }

  updatePassword(passwordUpdateDTO: PasswordUpdateDTO): Observable<OfficeUser> {
    return this.apiService.execute<OfficeUser>(new UpdatePasswordRequest(passwordUpdateDTO));
  }
}
