// Angular
import {Component, EventEmitter, Input, Output} from '@angular/core';
import {createSortingStateWithDirection, TableHeaderItem} from "../../models/table-header-item.model";
import {SortOrder} from "../../enums/sort.order";

@Component({
  selector: 'app-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent {
  @Input() columns: TableHeaderItem[] = [];
  @Input() context: string = '';
  @Input() showDetailBtn: boolean = false;
  @Input() showSelectAll: boolean = false;
  @Input() selectAllChecked: boolean = false;
  @Input() selectAllIndeterminate: boolean = false;
  @Input() selectAllVisible: boolean = false;

  @Output() sortingChanged: EventEmitter<TableHeaderItem> = new EventEmitter<TableHeaderItem>();
  @Output() selectAllChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly SortOrderEnum = SortOrder;

  handleSelectAllChange(): void {
    this.selectAllChanged.emit(!this.selectAllChecked);
  }

  updateSorting(columnId: string, direction: SortOrder): void {
    const column = this.columns.find(col => col.id === columnId);

    if (!column || !column.sortable) {
      return;
    }

    this.resetAllSortingStates(column);
    column.sortingState.active = true;
    column.sortingState.sortDirection = direction;
  }

  handleSortingChange(column: TableHeaderItem): void {
    if (!column.sortable) {
      return;
    }

    this.resetAllSortingStates(column);
    this.toggleSorting(column);

    this.sortingChanged.emit(column);
  }

  get classNameFromContext(): string {
    return this.context.replace('.', '-').toLowerCase();
  }

  private resetAllSortingStates(column: TableHeaderItem): void {
    for (let col of this.columns) {
      if (col.id === column.id) {
        continue;
      }

      col.sortingState.sortDirection = col.startSortingDirection;
      col.sortingState.active = false;
    }
  }

  private toggleSorting(column: TableHeaderItem): void {
    if (!column.sortingState || !column.sortingState.active) {
      column.sortingState = createSortingStateWithDirection(column.startSortingDirection);
      return;
    }

    const sortOrder = column.sortingState.sortDirection === SortOrder.DESC ? SortOrder.ASC : SortOrder.DESC;
    column.sortingState = createSortingStateWithDirection(sortOrder);
  }
}
