// Angular
import { Directive, EventEmitter, HostBinding, HostListener, Output } from '@angular/core';

@Directive({
  selector: '[app-drag-drop]',
})
export class DragDropDirective {
  @Output() filesDropped: EventEmitter<any> = new EventEmitter<any>();

  @HostBinding('class.fileover') fileOver: boolean = false;

  @HostListener('dragover', ['$event'])
  onDragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();

    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();

    this.fileOver = false;
  }

  @HostListener('drop', ['$event'])
  onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();

    this.fileOver = false;
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      this.filesDropped.emit(files);
    }
  }
}
