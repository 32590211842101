import {BankAccountOwnerType} from "../../../shared";
import {Client} from '../../clients/models';

export interface AccountDetailsFilter {
  excludeIncoming: boolean,
  excludeOutgoing: boolean;
  counterpartName: string|null;
  counterpartReference: string|null;
  remittanceInformation: string|null;
  description: string|null;
  dateFrom: string|null;
  dateTo: string|null;
  bankAccountOwnerType: BankAccountOwnerType;
  categories: CategoryFilter[];
  uncategorized: boolean;
  bankAccountCurrency: string;
  amountMin: string | null;
  amountMax: string | null;
  clientId: string | null;
  tagIds: string[];
}

export interface CategoryFilter {
  mainCategoryName: string;
  subCategoryName: string|null;
  subCategoryLabel: string|null;
  categoryName: string;
  text: string;
  iconUrl?: string;
}

export interface MainCategoryFilter {
  mainCategoryName: string;
  text: string;
  iconUrl?: string;
}

export function createEmptyAccountDetailsFilter(): AccountDetailsFilter {
  return {
    excludeIncoming: false,
    excludeOutgoing: false,
    counterpartName: null,
    counterpartReference: null,
    remittanceInformation: null,
    description: null,
    dateFrom: null,
    dateTo: null,
    bankAccountOwnerType: BankAccountOwnerType.OFFICE,
    categories: [],
    uncategorized: false,
    bankAccountCurrency: 'EUR',
    amountMin: null,
    amountMax: null,
    clientId: null,
    tagIds: [],
  };
}
