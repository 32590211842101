<div class="amount">
  <i *ngIf="icon" class="fas {{icon}} icon"></i>
  <input
    id="{{controlId}}"
    autocomplete="none"
    class="form-control"
    [ngClass]="{'is-invalid': isInputSet() && !isEditing() && !isValid(),'is-valid': isInputSet() && !isEditing() && isInputSet() && isValid()}"
    [formControl]="control"
    (blur)="onBlur()"
    (input)="onInput()"
    min="{{min}}"
    max="{{max}}"
  />
  <a *ngIf="clearable && control?.value" class="clear" (click)="clearInput()">
    <i class="fas fa-times"></i>
  </a>
</div>
