// Angular
// core
import {APIRequest, Method} from 'src/app/core/models';
// shared
import {AppSettings} from 'src/app/shared';
// local
import {TransactionsForAccountBrowseRequest} from '../models';
import {RequestParamsHelper} from "./request-params-helper";

export class ListTransactionsForAccountRequest implements APIRequest {

  method: Method = Method.POST;
  path: string;
  size: number = AppSettings.LIST_SIZE;
  body: any;

  constructor(bankAccountId: string, transactionsForAccountBrowseRequest: TransactionsForAccountBrowseRequest) {
    this.path = '/bank-accounts/' + bankAccountId + '/transactions';
    this.body = RequestParamsHelper.buildAccountDetailsFiltersRequestBody(transactionsForAccountBrowseRequest, this.size);
  }
}


