// Angular
import {NgModule} from '@angular/core';
// local
import {AmountColorPipe} from './pipes/amount-color.pipe';
import {BankAccountExpiresColorPipe} from './pipes/bank-acount-expires-color.pipe';
import {CamelToKebabPipe} from './pipes/camel-to-kebab.pipe';
import {ClientColorPipe} from './pipes/client-color.pipe';
import {ClientTypeIconPipe} from './pipes/client-type-icon.pipe';
import {EnumToArrayPipe} from './pipes/enum-to-array.pipe';
import {IbanPipe} from './pipes/iban.pipe';
import {ShowReauthorizeBankAccountPipe} from './pipes/show-reauthorize-bank-account.pipe';
import {EuroAccountPipe} from './pipes/euro-account.pipe';
import {IbanSmallPipe} from './pipes/iban-small.pipe';
import {AssigneePipe} from './pipes/assignee.pipe';
import {StructuredRemittancePipe} from './pipes/structured-remittance.pipe';
import {TrimInputDirective} from "../directives/trim-input.directive";
import {AmountPipe} from "./pipes/amount.pipe";

@NgModule({
  exports: [
    AmountColorPipe,
    AssigneePipe,
    BankAccountExpiresColorPipe,
    CamelToKebabPipe,
    ClientColorPipe,
    ClientTypeIconPipe,
    EnumToArrayPipe,
    EuroAccountPipe,
    IbanPipe,
    IbanSmallPipe,
    ShowReauthorizeBankAccountPipe,
    StructuredRemittancePipe,
    TrimInputDirective,
    AmountPipe,
  ],
  declarations: [
    AmountColorPipe,
    AssigneePipe,
    BankAccountExpiresColorPipe,
    CamelToKebabPipe,
    ClientColorPipe,
    ClientTypeIconPipe,
    EnumToArrayPipe,
    EuroAccountPipe,
    IbanPipe,
    IbanSmallPipe,
    ShowReauthorizeBankAccountPipe,
    StructuredRemittancePipe,
    TrimInputDirective,
    AmountPipe,
  ],
  providers: [IbanPipe]
})
export class SharedUtilsModule {}
