import {APIRequest, Method} from "../../../core/models";
import {KeyValue} from "@angular/common";
import {AppSettings} from "../../../shared";
import {EditPayment, PaymentBrowseRequest} from "../models/payment.model";

export class CreatePaymentRequest implements APIRequest {

  method: Method = Method.POST;
  path: string = '/payments';
  body: EditPayment;

  constructor(payment: EditPayment) {
    this.body = payment;
  }
}
