// Angular
import { Injectable } from '@angular/core';
// modules
import { AccountsStoreService } from 'src/app/modules/accounts/service/accounts-store.service';
import { ClientsStoreService } from 'src/app/modules/clients/services/clients-store.service';
import { InboxNotificationsStoreService as InboxStoreService } from 'src/app/modules/inbox/services/inbox-notifications-store.service';
import { OfficeStoreService } from 'src/app/modules/configuration/services/office-store.service';
// local
import { UserStoreService } from '../user/user-store.service';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  constructor(
    private accountsStoreService: AccountsStoreService,
    private clientsStoreService: ClientsStoreService,
    private inboxStoreService: InboxStoreService,
    private officeStoreService: OfficeStoreService,
    private userStoreService: UserStoreService
  ) {}

  clearStores() {
    this.accountsStoreService.clearStore();
    this.clientsStoreService.clearStore();
    this.inboxStoreService.clearStore();
    this.officeStoreService.clearStore();
    this.userStoreService.clearStore();
  }
}
