// Angular
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// 3rd-party
import {BsDatepickerModule} from "ngx-bootstrap/datepicker";
import {ModalModule} from "ngx-bootstrap/modal";
import {TranslateModule} from "@ngx-translate/core";
// components
import {BasicPageComponent} from './basic-page/basic-page.component';
import {CardSmallComponent} from './card-small/card-small.component';
import {ConfirmPopupComponent} from './confirm-popup/confirm-popup.component';
import {DragDropDirective} from '../directives/drag-drop.directive';
import {DropdownFilterComponent} from './dropdown-filter/dropdown-filter.component';
import {LoaderComponent} from './loader/loader.component';
import {PasswordFieldComponent} from './password-field/password-field.component';
import {ProgressBarComponent} from './progress-bar/progress-bar.component';
import {QueryFilterComponent} from './query-filter/query-filter.component';
import {TableHeaderComponent} from './table-header/table-header.component';
import {ToggleFilterComponent} from './toggle-filter/toggle-filter.component';
// utils
import {SharedUtilsModule} from '../utils/shared-utils.module';
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import { RemittanceInformationFormControlComponent } from './remittance-information-form-control/remittance-information-form-control.component';
import { AmountInputComponent } from './amount-input/amount-input.component';
import { BankAccountStatusIconComponent } from './bank-account-status-icon/bank-account-status-icon.component';

@NgModule({
  declarations: [
    BasicPageComponent,
    CardSmallComponent,
    ConfirmPopupComponent,
    DragDropDirective,
    DropdownFilterComponent,
    LoaderComponent,
    PasswordFieldComponent,
    ProgressBarComponent,
    QueryFilterComponent,
    TableHeaderComponent,
    ToggleFilterComponent,
    RemittanceInformationFormControlComponent,
    AmountInputComponent,
    BankAccountStatusIconComponent,
  ],
  exports: [
    BasicPageComponent,
    CardSmallComponent,
    ConfirmPopupComponent,
    DragDropDirective,
    DropdownFilterComponent,
    LoaderComponent,
    PasswordFieldComponent,
    ProgressBarComponent,
    QueryFilterComponent,
    TableHeaderComponent,
    ToggleFilterComponent,
    RemittanceInformationFormControlComponent,
    AmountInputComponent,
    BankAccountStatusIconComponent
  ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        SharedUtilsModule,
        ModalModule.forRoot(),
        TranslateModule.forChild(),
        BsDatepickerModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot()
    ]
})
export class SharedComponentsModule { }
