// core
import { APIRequest, Method } from 'src/app/core/models';

export class UserDetailRequest implements APIRequest {
  method: Method = Method.GET;
  path: string;

  constructor(userId: string) {
    this.path = '/office/users/' + userId;
  }
}
