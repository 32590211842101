// Angular
import {KeyValue} from '@angular/common';
// core
import {APIRequest, Method} from 'src/app/core/models';
// shared
import {AppSettings, NotificationsBrowseRequest, SortOrder} from 'src/app/shared';

export class ListNotificationsRequest implements APIRequest {
  method: Method = Method.GET;
  path: string = '/notifications';
  requestParams: KeyValue<string, string>[];

  size: number = AppSettings.LIST_SIZE;
  sort: SortOrder = SortOrder.DESC;
  sortField: string = 'date';

  constructor(notificationsBrowseRequest: NotificationsBrowseRequest) {
    this.requestParams = [
      { key: 'page', value: notificationsBrowseRequest.currentPage.toString() },
      { key: 'size', value: this.size.toString() },
      { key: 'sortField', value: this.sortField },
      { key: 'sortOrder', value: this.sort },
    ];

    if (notificationsBrowseRequest.unprocessed) {
      this.requestParams.push({
        key: 'unprocessed',
        value: notificationsBrowseRequest.unprocessed.toString(),
      });
    }
  }
}
