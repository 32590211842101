import {EventEmitter} from "@angular/core";

export class GlobalEmitter {
  private static EMITTERS: { [ID: string]: EventEmitter<any> } = {};

  public static of<T>(ID: string): EventEmitter<T> {
    if (!this.EMITTERS[ID]) {
      this.EMITTERS[ID] = new EventEmitter<T>();
    }

    return this.EMITTERS[ID] as EventEmitter<T>;
  }

  public static reset(): void {
    this.EMITTERS = {};
  }

  public static remove(ID: string) {
    if (!this.EMITTERS[ID]) {
      return;
    }

    this.EMITTERS[ID].complete();
    delete this.EMITTERS[ID];
  }

  static get emitters(): { [p: string]: EventEmitter<any> } {
    return this.EMITTERS;
  }
}

export const UPDATE_CLIENTS_WITH_NOTIFICATIONS_COUNT_EMITTER_KEY: string = 'updateClientsWithNotificationsCount';
export const UPDATE_UNLINKED_ACCOUNTS_COUNT_EMITTER_KEY: string = 'updateUnlinkedAccountsCount';
export const UPDATE_ACCOUNTS_WITH_WARNING_COUNT_EMITTER_KEY: string = 'updateAccountsWithWarningCount';
export const UPDATE_PAYMENT_COUNT_EMITTER_KEY: string = 'updatePaymentCount';
export const UPDATE_OPEN_NOTIFICATIONS_COUNT_EMITTER_KEY: string = 'updateOpenNotificationsCount';
