import { SortOrder } from '../enums/sort.order';

export interface TableHeaderItem {
  id: string; // This should be the name how we can send it to the back-end
  name: string;
  sortable: boolean;
  startSortingDirection: SortOrder,
  sortingState: SortingState;
  hideLabel: boolean;
}

export interface SortingState {
  active: boolean;
  sortDirection: SortOrder;
}

export function createTableHeaderItem(id: string,
                                      name: string,
                                      sortable: boolean,
                                      active: boolean = false,
                                      startSortingDirection: SortOrder = SortOrder.ASC,
                                      hideLabel: boolean = false): TableHeaderItem {
  return {
    id,
    name,
    sortable,
    startSortingDirection,
    sortingState: {
      active,
      sortDirection: startSortingDirection
    },
    hideLabel
  };
}

export const CLIENT_HEADERS: TableHeaderItem[] = [
  createTableHeaderItem('lastName', 'client', true, true),
  createTableHeaderItem('unprocessedNotificationsCount', 'openNotifications', true),
  createTableHeaderItem('latestNotificationDate', 'latestNotification', true),
  createTableHeaderItem('account', 'account', false),
  createTableHeaderItem('balance', 'balance', false)
];

export const CLIENT_HEADERS_FOR_COURTS: TableHeaderItem[] = [
  createTableHeaderItem('lastName', 'client', true, true),
  createTableHeaderItem('address', 'address', true),
  createTableHeaderItem('officeName', 'officeName', true),
  createTableHeaderItem('account', 'account', false),
  createTableHeaderItem('balance', 'balance', false)
];

export const ARCHIVED_CLIENT_HEADERS: TableHeaderItem[] = [
  createTableHeaderItem('lastName', 'client', true, true),
  createTableHeaderItem('account', 'account', false),
];

export const ACCOUNT_HEADERS: TableHeaderItem[] = [
  createTableHeaderItem('status', 'status', false, false, SortOrder.ASC,true),
  createTableHeaderItem('bank', 'bankInfo', false),
  createTableHeaderItem('label', 'label', true),
  createTableHeaderItem('reference', 'accountNumber', true),
  createTableHeaderItem('currentBalance', 'balance', true),
  createTableHeaderItem('synchronizedAt', 'synced', true),
  createTableHeaderItem('linkedClient.lastName', 'linkedTo', false),
];

export const ARCHIVED_ACCOUNT_HEADERS: TableHeaderItem[] = [
  createTableHeaderItem('bank', 'bankInfo', false),
  createTableHeaderItem('label', 'label', true, true, SortOrder.ASC),
  createTableHeaderItem('reference', 'accountNumber', true),
  createTableHeaderItem('linkedClient.lastName', 'linkedTo', false),
  createTableHeaderItem('restore', 'restore', false, false, SortOrder.ASC, true),
];

export const ACCOUNT_DETAILS_HEADER: TableHeaderItem[] = [
  createTableHeaderItem('category', 'category', false),
  createTableHeaderItem('tags', 'tags', false),
  createTableHeaderItem('executionDate', 'date', true, true, SortOrder.DESC),
  createTableHeaderItem('counterpartName', 'counterpart', true),
  createTableHeaderItem('details', 'details', false),
  createTableHeaderItem('amount', 'amount', true),
] ;

export const PAYMENT_HEADERS: TableHeaderItem[] = [
  createTableHeaderItem('fromClient', 'fromClient', false),
  createTableHeaderItem('fromAccount', 'fromAccount', false),
  createTableHeaderItem('amount', 'amount', false),
  createTableHeaderItem('toAccount', 'toAccount', false),
  createTableHeaderItem('remittanceInfo', 'remittanceInfo', false),
  createTableHeaderItem('executionDate', 'executionDate', true)
];

export function createSortingStateWithDirection(direction: SortOrder): SortingState {
  return {
    active: true,
    sortDirection: direction
  };
}
