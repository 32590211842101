// Angular
import { Pipe, PipeTransform } from '@angular/core';
// local
import { BankAccount } from '../../models/bank-account.model';
import { AppSettings } from '../../constants/app-settings.const';

@Pipe({ name: 'euroAccount' })
export class EuroAccountPipe implements PipeTransform {
  transform(bankAccounts: BankAccount[]): BankAccount[] {
    return bankAccounts.filter((ba) => ba.currency === AppSettings.EUR_CODE);
  }
}
