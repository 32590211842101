// core
import { APIRequest, Method } from 'src/app/core/models';

export class AiarInfoForFinancialInstitutionRequest implements APIRequest {
  method: Method = Method.GET;
  path: string;

  constructor(financialInstitutionId: string) {
    this.path = `/financial-institutions/${financialInstitutionId}/aiar-info`;
  }
}
