// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  release: '0.0.1',
  production: false,
  api: {
    host: 'https://api-dev.backoffice.roov.app',
  },
  sentry: {
    env: 'develop',
    showPopup: false,
  },
  firebase: {
    config: {
      apiKey: 'AIzaSyB1gF97lHo9g4i36F-WdRyco0p2Y_06q5A',
      authDomain: 'housepass-all.firebaseapp.com',
      databaseURL: 'https://housepass-all.firebaseio.com',
      projectId: 'housepass-all',
      storageBucket: 'housepass-all.appspot.com',
      messagingSenderId: '118380300445',
      appId: '1:118380300445:web:4d508b1e27c54cba1aac74',
      measurementId: 'G-SJQKKCVZ24',
    },
    maintenanceKey: 'maintenanceModeBusinessDEV',
    versionKey: 'versionBusinessDEV'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
