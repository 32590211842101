// core
import { APIRequest, Method } from 'src/app/core/models';
import { KeyValue } from '@angular/common';

export class CountBankAccountsRequest implements APIRequest {
  method: Method = Method.GET;
  path: string;
  requestParams: KeyValue<string, string>[];

  constructor(onlyWithWarning: boolean) {
    this.path = '/bank-accounts/count';
    this.requestParams = [{ key: 'onlyWithWarning', value: onlyWithWarning.toString() }];
  }
}
