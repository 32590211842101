// Angular
import {Component, OnDestroy, OnInit} from '@angular/core';
// 3rd-party
import {initializeApp} from 'firebase/app';
import {fetchAndActivate, getBoolean, getRemoteConfig, RemoteConfig} from 'firebase/remote-config';
import {interval, Subscription} from 'rxjs';
// core
import {AppService, TranslationService} from './core/services';
// environment
import {environment} from 'src/environments/environment';
import {isNumeric} from "rxjs/internal-compatibility";
import {getString} from "@firebase/remote-config";

@Component({
  selector: 'body',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  maintenanceMode: boolean = false;
  currentVersionNumber: number = 0;
  remoteConfig: RemoteConfig | null = null;

  intervalSubscription: Subscription | null = null;

  constructor(private appService: AppService, private translationService: TranslationService) {}

  ngOnInit() {
    this.initializeFirebase();
    this.setLocale();
  }

  private initializeFirebase(): void {
    this.loading = true;
    const app = initializeApp(environment.firebase.config);
    this.remoteConfig = getRemoteConfig(app);

    this.remoteConfig.settings.minimumFetchIntervalMillis = 1;
    this.remoteConfig.settings.fetchTimeoutMillis = 60000
    this.remoteConfig.defaultConfig = ({
      [environment.firebase.maintenanceKey]: false,
      [environment.firebase.versionKey]: 0,
    })

    this.fetchAndActivateFirebaseRemoteConfig();
  }

  callFirebaseEveryHour() {
    this.intervalSubscription = interval(3600000).subscribe(() => {
      fetchAndActivate(this.remoteConfig!).then(() => {
        this.maintenanceMode = getBoolean(this.remoteConfig!, environment.firebase.maintenanceKey);
        const newVersion = getString(this.remoteConfig!, environment.firebase.versionKey);
        this.checkVersion(this.convertVersionToNumber(newVersion));
        console.log(`The current version of Roov Business is: ${newVersion}`);
      }, () => {
        this.maintenanceMode = false;
      })
    })
  }

  fetchAndActivateFirebaseRemoteConfig(): void {
    fetchAndActivate(this.remoteConfig!).then(() => {
      this.maintenanceMode = getBoolean(this.remoteConfig!, environment.firebase.maintenanceKey);
      const version =getString(this.remoteConfig!, environment.firebase.versionKey);
      this.currentVersionNumber = this.convertVersionToNumber(version);
      console.log(`The current version of Roov Business is: ${version}`);
      this.callFirebaseEveryHour();
      this.loading = false;
    }, () => {
      this.maintenanceMode = false;
      this.callFirebaseEveryHour();
      this.loading = false;
    })
  }

  private setLocale(): void {
    this.translationService.setLanguages();
    this.translationService.setTranslationCode();
  }

  ngOnDestroy() {
    this.appService.clearStores();
    if (this.intervalSubscription) this.intervalSubscription.unsubscribe();
  }

  private checkVersion(newVersion: number): void {
    if (this.currentVersionNumber < newVersion) {
      alert(this.translationService.translate('newVersion'));
      window.location.reload();
    }

    this.currentVersionNumber = newVersion;
  }

  private convertVersionToNumber(newVersion: string): number {
    const parts = newVersion.split('.').filter(part => isNumeric(part));

    if (parts.length !== 3) {
      throw new Error('The new version should be like: 1.2.3');
    }

    return Number(parts[0]) * 10000 + Number(parts[1]) * 100 + Number(parts[2]);
  }
}
