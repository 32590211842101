// Angular
import {KeyValue} from '@angular/common';
// core
import {APIRequest, Method} from 'src/app/core/models';
// shared
import {AppSettings} from 'src/app/shared';
// local
import {BankAccountsBrowseRequest} from '../models';

export class ListArchivedBankAccountsRequest implements APIRequest {
  method: Method = Method.GET;
  path: string = '/bank-accounts/archived';
  requestParams: KeyValue<string, string>[];

  size: number = AppSettings.LIST_SIZE;

  constructor(bankAccountsBrowseRequest: BankAccountsBrowseRequest) {
    this.requestParams = [
      { key: 'page', value: bankAccountsBrowseRequest.currentPage.toString() },
      { key: 'size', value: this.size.toString() },
      { key: 'sortOrder', value: bankAccountsBrowseRequest.sortDirection  },
      { key: 'sortField', value: bankAccountsBrowseRequest.sortColumn },
    ];

    if (bankAccountsBrowseRequest.query) {
      this.requestParams.push({ key: 'query', value: bankAccountsBrowseRequest.query });
    }

    if (bankAccountsBrowseRequest.financialInstitutionId !== 'ALL') {
      this.requestParams.push({ key: 'financialInstitutionId', value: bankAccountsBrowseRequest.financialInstitutionId })
    }
  }
}
