// Angular
import { Pipe, PipeTransform } from '@angular/core';
// modules
import { OfficeUser } from 'src/app/modules/configuration/models';

@Pipe({ name: 'assignee' })
export class AssigneePipe implements PipeTransform {
  transform(userId: string, officeUsers: OfficeUser[]): string {
    if (userId) {
      let user = officeUsers.find((user) => user.id === userId);

      return user ? user.firstName + ' ' + user.lastName : '-';
    } else {
      return '-';
    }
  }
}
