<div class="remittance-information" [class.structured]="!isUnstructured">
 <ng-container *ngIf="isUnstructured">
   <input
     autocomplete="none"
     class="form-control"
     type="text"
     maxlength="140"
     (ngModelChange)="infoUpdated($event)"
     [ngModel]="info"
     (blur)="onBlur()"
     (input)="onInput()"
     [ngClass]="{'is-invalid': !isEditing() && !isValid(), 'is-valid': !isEditing() && isInputSet() && isValid()}"
   />
   <div class="icon" (click)="updateType(RemittanceInformationType.STRUCTURED)">
     <span>x</span>/<span>x</span>
   </div>

   <div class="invalid-feedback" *ngIf="showError && shouldValidate() && formError">
     {{formError.message | translate:formError.messageValues }}
   </div>
 </ng-container>

  <ng-container *ngIf="!isUnstructured">
    <input
      autocomplete="none"
      class="form-control"
      type="text"
      (ngModelChange)="infoUpdated($event)"
      [ngModel]="info | structuredRemittance"
      (blur)="onBlur()"
      (input)="maxLengthAndNumericOnly($event)"
      [ngClass]="{'is-invalid': !isEditing() && !isValid(), 'is-valid': !isEditing() && isInputSet() && isValid()}"
    />

    <div class="icon" (click)="updateType(RemittanceInformationType.UNSTRUCTURED)">
      A<span>b</span>
    </div>

    <div class="invalid-feedback" *ngIf="showError && shouldValidate() && formError">
      {{formError.message | translate:formError.messageValues }}
    </div>
  </ng-container>
</div>
