import {APIRequest, Method} from "../../../core/models";

export class DeleteReportRequest implements APIRequest {
  method: Method = Method.DELETE;
  path: string;

  constructor(clientId: string, reportId: string) {
    this.path = '/clients/' + clientId + '/reports/' + reportId;
  }
}
