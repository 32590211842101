// Angular
import {Injectable} from '@angular/core';
// 3rd-party
import {BehaviorSubject, Observable} from 'rxjs';
// shared
import {AppSettings, User} from 'src/app/shared';
// local
import {TokenService} from '../token/token.service';

@Injectable({
  providedIn: 'root',
})
export class UserStoreService {
  private _user: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  public user: Observable<User | null> = this._user.asObservable();

  private _selectedAssignees: BehaviorSubject<string[]> = new BehaviorSubject<string[]>(window.localStorage.getItem(AppSettings.LOCAL_STORAGE_ASSIGNEE)?.split(',') ?? []);

  constructor(private tokenService: TokenService) {}

  updateUser(user: User | null): void {
    this._user.next(user);
  }

  getUserId(): string {
    return this._user.getValue()?.id ?? '';
  }

  getUserFullName(): string {
    return this._user.getValue()?.fullName ?? '';
  }

  getOfficeName(): string {
    return this._user.getValue()?.officeName ?? '';
  }

  setSelectedAssignees(assignees: string[]) {
    window.localStorage.setItem(AppSettings.LOCAL_STORAGE_ASSIGNEE, assignees.toString());
    this._selectedAssignees.next(assignees);
  }

  getSelectedAssignees(): string[] {
    return this._selectedAssignees.getValue().filter(assignee => {
      return !!assignee;
    });
  }

  logout(): void {
    this.updateUser(null);
    this.setSelectedAssignees([]);
    this.tokenService.removeToken();
  }

  clearStore(): void {
    this._user.next(null);
  }
}
