// Angular
import { ErrorHandler } from '@angular/core';
// 3rd-party
import { captureException, showReportDialog } from '@sentry/angular';
// shared
import { AppSettings } from './shared';
// environment
import { environment } from 'src/environments/environment';

export class SentryErrorHandler implements ErrorHandler {
  private dialogShownTimeout: number | null = null;

  handleError(error: any) {
    if (
      !environment.sentry.showPopup ||
      (error &&
        ((error.status && AppSettings.UNTRACKED_ERROR_STATUSES.some((status) => status === error.status)) ||
          (error.originalError &&
            error.originalError.status &&
            AppSettings.UNTRACKED_ERROR_STATUSES.some((status) => status === error.originalError.status))))
    ) {
      console.error(error);
      return;
    }

    const chunkFailedMessage = /Loading chunk \d+ failed/;
    if (chunkFailedMessage.test(error.message)) {
      const userLang = navigator.language || (navigator as any).userLanguage;
      if (userLang === 'nl') {
        alert('Er is een nieuwe versie beschikbaar.\nDe pagina zal vernieuwen.')
      } else {
        alert('A new version is available.\nThe page will reload.')
      }
      window.location.reload();
      return;
    }

    const eventId = captureException(error.originalError || error);

    // Delay dialog in case multiple errors are triggered within 250ms
    if (this.dialogShownTimeout) {
      clearTimeout(this.dialogShownTimeout);
    }
    this.dialogShownTimeout = setTimeout(() => {
      showReportDialog({ eventId });
    }, 250);
  }
}
