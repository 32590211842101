<div class='progress-bar-wrapper'>
  <div *ngFor='let step of steps' class='step'>
    <i class='fas' [class.fa-check]='step < currentStep' [class.colored]='step <= currentStep'></i>
    <ng-container *ngIf='step !== 0 && step !== steps.length - 1'>
      {{ 'step' | translate }} {{ step + 1}}
    </ng-container>
    <ng-container *ngIf='step === 0'>
      {{ firstStepLabel | translate }}
    </ng-container>
    <ng-container *ngIf='step === steps.length - 1'>
      {{ lastStepLabel | translate }}
    </ng-container>
    <span *ngIf='step !== 0' class='arrow' [class.colored]='step <= currentStep'></span>
  </div>
</div>
