// core
import { APIRequest, Method } from 'src/app/core/models';
import {BatchPayments} from "../models/batch-payments";

export class CreateCreditorPaymentsRequest implements APIRequest {
  method: Method = Method.POST;
  path: string;
  body: BatchPayments;

  constructor(payments: BatchPayments ) {
    this.path = '/batch/payments';
    this.body= payments;
  }
}
