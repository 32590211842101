// Angular
import { Injectable } from '@angular/core';
// shared
import { AppSettings } from 'src/app/shared';
import {JwtHelperService} from "@auth0/angular-jwt";

@Injectable({ providedIn: 'root' })
export class TokenService {
  getBearerToken(): string | null {
    return window.localStorage.getItem(AppSettings.LOCAL_STORAGE_TOKEN_KEY);
  }

  setBearerToken(token: string): void {
    window.localStorage.setItem(AppSettings.LOCAL_STORAGE_TOKEN_KEY, token);
    this.setRolesFromToken(token);
  }

  removeToken(): void {
    window.localStorage.removeItem(AppSettings.LOCAL_STORAGE_TOKEN_KEY);
    this.removeRoles();
  }

  setRolesFromToken(token: string): void {
    const helper = new JwtHelperService();
    let roles : string[] | null = helper.decodeToken(token).roles;

    if (roles) {
      roles = roles.map(role => role.startsWith('ROLE_') ? role.slice(5) : role);
      window.localStorage.setItem(AppSettings.LOCAL_STORAGE_ROLES_KEY, JSON.stringify(roles));
    }
  }

  removeRoles(): void {
    window.localStorage.removeItem(AppSettings.LOCAL_STORAGE_ROLES_KEY);
  }

  getRoles(): string[] | null {
    const roles = window.localStorage.getItem(AppSettings.LOCAL_STORAGE_ROLES_KEY);

    if (!roles) {
      return null;
    }

    return JSON.parse(roles);
  }
}
