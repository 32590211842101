// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// app
import { routes } from 'src/app/_nav';
// local
import { TokenService } from '../token/token.service';
import {RoleCheckerService} from "./role-checker.service";
import {Role} from "../../models/role.enum";

@Injectable({
  providedIn: 'root',
})
export class AuthenticationGuardService implements CanActivate {
  constructor(
    private router: Router,
    private tokenService: TokenService,
    private roleChecker: RoleCheckerService
  ) {}

  /**
   * We now can use in our router modules something like the following line:
   * { path: 'change-language', component: ChangeLanguageComponent, canActivate: [AuthGuardService], data: {role: Role.OFFICE_USER} },
   *
   * Or if you want to support any of these roles
   *
   * { path: 'change-language', component: ChangeLanguageComponent, canActivate: [AuthGuardService], data: {anyRole: [Role.OFFICE_USER]} },
   *
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const bearerToken = this.tokenService.getBearerToken();

    if (!bearerToken) {
      this.router.navigate([routes.LOGIN], { queryParams: { 'return-uri': state.url } }).then();
      return false;
    }

    const data = route.data;
    if (data['role'] && !this.roleChecker.hasRole(data['role'])) {

      if (data['redirectUri']) {
        this.router.navigateByUrl(data['redirectUri']).then();
      }

      return false;
    }

    if (data['anyRole']) {
      const roles: Role[] = Array.isArray(data['anyRole']) ? data['anyRole'] as Role[] : [data['anyRole']] as Role[];

      if (!this.roleChecker.hasAnyOfThese(roles)) {
        this.router.navigateByUrl('/dashboard').then();
        return false;
      }
    }

    return true;
  }
}
