import {APIRequest, Method} from "../../../core/models";
import {KeyValue} from "@angular/common";
import {AppSettings} from "../../../shared";
import {PaymentBrowseRequest} from "../models/payment.model";

export class ListPaymentsRequest implements APIRequest {

  method: Method = Method.GET;
  path: string = '/payments';
  requestParams: KeyValue<string, string>[];

  size: number = AppSettings.LIST_SIZE;

  constructor(paymentBrowseRequest: PaymentBrowseRequest, fetchAll: boolean) {
    this.requestParams = [
      { key: 'page', value: (paymentBrowseRequest.currentPage).toString() },
      { key: 'size', value: fetchAll ? '10000' : this.size.toString() },
      { key: 'sortOrder', value: paymentBrowseRequest.sortDirection},
      { key: 'sortField', value: paymentBrowseRequest.sortColumn},
    ];

    if (paymentBrowseRequest.query) {
      this.requestParams.push({ key: 'query', value: paymentBrowseRequest.query });
    }

    if (paymentBrowseRequest.status) {
      this.requestParams.push({ key: 'status', value: paymentBrowseRequest.status });
    }
  }
}
