// Angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// local
import { PageNotFoundComponent } from './components';
import { CoreRoutingModule } from './core-routing.module';

@NgModule({
  imports: [CommonModule, CoreRoutingModule],
  declarations: [PageNotFoundComponent],
})
export class CoreModule {}
