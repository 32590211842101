// core
import {APIRequest, Method} from 'src/app/core/models';
// shared
import {UpdateBankAccount} from "../../../shared/models/update-bank-account";

export class UpdateBankAccountSettingsRequest implements APIRequest {
  method: Method = Method.PATCH;
  path: string;
  body: UpdateBankAccount;

  constructor(bankAccountId: string, updateBankAccount: UpdateBankAccount) {
    this.path = '/bank-accounts/' + bankAccountId;
    this.body = updateBankAccount;
  }
}
