// Angular
import {KeyValue} from '@angular/common';
// core
import {APIRequest, Method} from 'src/app/core/models';
// shared
import {AppSettings, SortOrder} from 'src/app/shared';
// local
import {ClientsBrowseRequest} from '../models';

export class ListArchivedClientsRequest implements APIRequest {
  method: Method = Method.GET;
  path: string = '/clients/archived';
  requestParams: KeyValue<string, string>[];

  size: number = AppSettings.LIST_SIZE;

  constructor(clientsBrowseRequest: ClientsBrowseRequest) {
    this.requestParams = [
      { key: 'page', value: clientsBrowseRequest.currentPage.toString() },
      { key: 'size', value: this.size.toString() },
      { key: 'sortOrder', value: clientsBrowseRequest.sortDirection },
      { key: 'sortField', value: clientsBrowseRequest.sortColumn },
    ];

    if (clientsBrowseRequest.query) {
      this.requestParams.push({
        key: 'query',
        value: clientsBrowseRequest.query,
      });
    }
  }
}
