// Angular
import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {Router} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
// 3rd-party
import {AppAsideModule, AppFooterModule, AppHeaderModule} from '@coreui/angular';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import * as Sentry from '@sentry/angular';
// core
import {ApiInterceptor} from './core/services';
import {CoreModule} from './core/core.module';
import {DefaultLayoutComponent} from './core/components';
// local
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {SentryErrorHandler} from './sentry-error-handler';
import {SharedComponentsModule} from "./shared/components/shared-components.module";
import {ToastrModule} from "ngx-toastr";
import {DragulaModule} from "ng2-dragula";
import {NgSelectModule} from "@ng-select/ng-select";
import {SharedUtilsModule} from "./shared";

const APP_CONTAINERS = [DefaultLayoutComponent];

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    SharedComponentsModule,
    AppAsideModule,
    AppFooterModule,
    AppHeaderModule,
    PerfectScrollbarModule,
    CoreModule,
    NgMultiSelectDropDownModule.forRoot(),
    BsDropdownModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    FormsModule,
    DragulaModule.forRoot(),
    NgSelectModule,
    SharedUtilsModule,
    ReactiveFormsModule
  ],
  declarations: [AppComponent, ...APP_CONTAINERS],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: SentryErrorHandler,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
