// Angular
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
// components
import { PageNotFoundComponent } from './components';

const coreRoutes: Routes = [{ path: 'not-found', component: PageNotFoundComponent }];

@NgModule({
  imports: [RouterModule.forChild(coreRoutes)],
  exports: [RouterModule],
})
export class CoreRoutingModule {}
