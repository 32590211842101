import {BankAccount} from './bank-account.model';
import {BrowseRequest} from './browse-request.model';
import {PagingResponse} from './paging.model';
import {DocumentShareResponseType} from "../enums/document-share-response-type.enum";
import {SimpleClient} from "./simple-client";

export interface NotificationsResult extends PagingResponse<Notification> {
  results: Notification[];
}

export interface Notification {
  id: string;
  clientId: string|null;
  bankAccount: BankAccount;
  dateCreated: string;
  dateUpdated: string;
  isClosed: boolean;
  isClosedBySystem: boolean;
  localizedTitle: string;
  localizedDescription: string;
  localizedDetails?: string;
  subNotifications: SubNotification[];
  expanded?: boolean;
  documentDTO: SimpleDocument|null;
  client: SimpleClient;
}

export interface SubNotification {
  dateCreated: string;
  localizedDescription: string;
}

export interface NotificationsBrowseRequest extends BrowseRequest {
  unprocessed?: boolean;
}

export interface SimpleDocument {
  id: string;
  name: string;
  shareResponse: DocumentShareResponseType|null;
}
