<ng-container *ngIf="bankAccount">
  <i *ngIf="getBankAccountStatus(bankAccount) === BankAccountStatus.ARCHIVED"
     class="fas fa-archive gray"
     title="{{ 'archived' | translate }}"></i>
  <i *ngIf="getBankAccountStatus(bankAccount) === BankAccountStatus.AUTH_INVALID"
     class="fas fa-exclamation-triangle orange"
     title="{{ 'ACCOUNTS.authInvalid' | translate }}"></i>
  <i *ngIf="getBankAccountStatus(bankAccount) === BankAccountStatus.EXPIRING"
     class="fas fa-exclamation-triangle orange"
     title="{{ 'ACCOUNTS.authExpires' | translate }} {{bankAccount.expiresAt | date: 'dd/MM/yyyy'}}"></i>
  <i *ngIf="getBankAccountStatus(bankAccount) === BankAccountStatus.MOVED_TO_ISABEL_ACTIVE || getBankAccountStatus(bankAccount) === BankAccountStatus.MOVED_TO_ISABEL_INACTIVE"
     class="fas fa-exclamation-triangle orange"
     title="{{ 'ACCOUNTS.movedToIsabel' | translate }}"></i>
  <i *ngIf="getBankAccountStatus(bankAccount) === BankAccountStatus.TECHNICAL_FAILURE"
     class="fas fa-exclamation-triangle orange"
     title="{{ 'ACCOUNTS.technicalFailure' | translate }}"></i>
  <i *ngIf="getBankAccountStatus(bankAccount) === BankAccountStatus.NOT_FOUND_IN_ISABEL"
     class="fas fa-exclamation-triangle orange"
     title="{{ 'ACCOUNTS.notFoundInIsabel' | translate }}"></i>
</ng-container>
