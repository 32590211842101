// Angular
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'bankAccountExpiresColor' })
export class BankAccountExpiresColorPipe implements PipeTransform {
  transform(value: string): string {
    const date = new Date(value);
    const currentDate = new Date();

    if (date) {
      let days = Math.floor((date.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);

      if (days > 10) {
        return 'hidden';
      } else if (days > 0) {
        return 'orange';
      } else {
        return 'red';
      }
    } else {
      return '';
    }
  }
}
