// core
import {APIRequest, Method} from 'src/app/core/models';
// local
import {UpdateCourtOfficeDTO} from '../models';

export class UpdateCourtOfficeRequest implements APIRequest {
  method: Method = Method.PUT;
  path: string = '/labour-court-offices';
  body: UpdateCourtOfficeDTO;

  constructor(updateCourtOfficeDTO: UpdateCourtOfficeDTO) {
    this.body = updateCourtOfficeDTO;
  }
}
