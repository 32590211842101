import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'amount',
})
export class AmountPipe implements PipeTransform {
  transform(value: unknown, ...args: string[]): unknown {
    if (value && Number(value)) {
      let options;
      if (args[0]) {
        options = {
          style: 'currency',
          currency: args[0] ?? '',
          minimumFractionDigits: 2,
        }
      } else {
        options = {
          minimumFractionDigits: 2,
        }
      }
      return new Intl.NumberFormat('nl-BE', options).format(Number(value));
    }
    return '-';
  }
}
