// Angular
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// Sentry
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';
// local
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { AppSettings } from './app/shared';

Sentry.init({
  dsn: 'https://17eb171182e448cabe22265b6138a37c@o198172.ingest.sentry.io/6154117',
  integrations: [
    new Integrations.BrowserTracing({
      tracingOrigins: [environment.api.host],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
  release: environment.release,
  environment: environment.sentry.env,
  beforeSend(event, hint) {
    if (
      hint &&
      (hint.originalException as HttpErrorResponse) &&
      (hint.originalException as HttpErrorResponse).status &&
      AppSettings.UNTRACKED_ERROR_STATUSES.some(
        (status) => (hint.originalException as HttpErrorResponse).status === status
      )
    ) {
      return null;
    } else {
      return event;
    }
  },
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));
