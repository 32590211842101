// core
import { APIRequest, Method } from 'src/app/core/models';
// local
import { UpdateOfficeDTO } from '../models';

export class UpdateOfficeRequest implements APIRequest {
  method: Method = Method.PUT;
  path: string = '/office';
  body: UpdateOfficeDTO;

  constructor(updateOfficeDTO: UpdateOfficeDTO) {
    this.body = updateOfficeDTO;
  }
}
