// shared
import {BankAccount, BrowseRequest, PagingResponse} from 'src/app/shared';
import {SortRequest} from "../../../shared/models/sort-request.model";

export interface BankAccountsResult extends PagingResponse<BankAccount> {
  results: BankAccount[];
}

export interface BankAccountsBrowseRequest extends BrowseRequest, SortRequest {
  financialInstitutionId: string;
  linkedStatus?: LinkedStatus;
  onlyWithWarning?: boolean;
  excludeAssigneesFromApi?: boolean
}

export enum LinkedStatus {
  ALL = 'ALL',
  LINKED = 'LINKED',
  UNLINKED = 'UNLINKED'
}
