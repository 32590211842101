// Angular
import {Injectable} from '@angular/core';
// 3rd-party
import {BehaviorSubject, Observable} from 'rxjs';
import {isEqual} from 'lodash';
// shared
import {deepCopy, SortOrder} from 'src/app/shared';
// local
import {BankAccountsBrowseRequest, TransactionsForAccountBrowseRequest} from '../models';
import {createEmptyAccountDetailsFilter} from "../models/account-details-filter.model";

@Injectable({
  providedIn: 'root',
})
export class AccountsStoreService {
  private bankAccountsBrowseRequestInitialValue: BankAccountsBrowseRequest = {
    currentPage: 1,
    query: '',
    financialInstitutionId: 'ALL',
    sortColumn: 'authorizationExpirationExpectedAt',
    sortDirection: SortOrder.ASC
  };

  private archivedBankAccountsBrowseRequestInitialValue: BankAccountsBrowseRequest = {
    currentPage: 1,
    query: '',
    financialInstitutionId: 'ALL',
    sortColumn: 'holderName',
    sortDirection: SortOrder.ASC
  };

  private transactionsBrowseRequestInitialValue: TransactionsForAccountBrowseRequest = {
    currentPage: 1,
    filters: createEmptyAccountDetailsFilter(),
    sortColumn: 'executionDate',
    sortDirection: SortOrder.DESC
  };

  private _currentBankAccountsBrowseRequest: BehaviorSubject<BankAccountsBrowseRequest> =
    new BehaviorSubject<BankAccountsBrowseRequest>(deepCopy(this.bankAccountsBrowseRequestInitialValue));

  private _currentArchivedBankAccountsBrowseRequest: BehaviorSubject<BankAccountsBrowseRequest> =
    new BehaviorSubject<BankAccountsBrowseRequest>(deepCopy(this.archivedBankAccountsBrowseRequestInitialValue));

  private _currentTransactionsBrowseRequest: BehaviorSubject<TransactionsForAccountBrowseRequest> =
    new BehaviorSubject<TransactionsForAccountBrowseRequest>(deepCopy(this.transactionsBrowseRequestInitialValue));

  public readonly currentBankAccountsBrowseRequest: Observable<BankAccountsBrowseRequest> =
    this._currentBankAccountsBrowseRequest.asObservable();

  public readonly currentArchivedBankAccountsBrowseRequest: Observable<BankAccountsBrowseRequest> =
    this._currentArchivedBankAccountsBrowseRequest.asObservable();

  public readonly currentTransactionsBrowseRequest: Observable<TransactionsForAccountBrowseRequest> =
    this._currentTransactionsBrowseRequest.asObservable();

  updateCurrentBankAccountsBrowseRequest(bankAccountsBrowseRequest: BankAccountsBrowseRequest): void {
    if (!isEqual(bankAccountsBrowseRequest, this._currentBankAccountsBrowseRequest.getValue())) {
      this._currentBankAccountsBrowseRequest.next(bankAccountsBrowseRequest);
    }
  }

  updateCurrentArchivedBankAccountsBrowseRequest(bankAccountsBrowseRequest: BankAccountsBrowseRequest): void {
    this._currentArchivedBankAccountsBrowseRequest.next(bankAccountsBrowseRequest);
  }

  updateCurrentTransactionsBrowseRequest(transactionsBrowseRequest: TransactionsForAccountBrowseRequest): void {
    this._currentTransactionsBrowseRequest.next(transactionsBrowseRequest);
  }

  resetPageBankAccountsBrowseRequest(): void {
    const currentRequest = this._currentBankAccountsBrowseRequest.getValue();
    currentRequest.currentPage = 1;
    this._currentBankAccountsBrowseRequest.next(currentRequest);
  }

  clearStore(): void {
    this._currentBankAccountsBrowseRequest.next(deepCopy(this.bankAccountsBrowseRequestInitialValue));
    this.clearArchivedStore();
    this.clearDetailStore();
  }

  clearArchivedStore(): void {
    this._currentArchivedBankAccountsBrowseRequest.next(deepCopy(this.archivedBankAccountsBrowseRequestInitialValue));
  }

  clearDetailStore(): void {
    this._currentTransactionsBrowseRequest.next(deepCopy(this.transactionsBrowseRequestInitialValue));
  }
}
