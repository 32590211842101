// Angular
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'amountColor' })
export class AmountColorPipe implements PipeTransform {
  transform(value?: number): string {
    if (value || value === 0) {
      if (value < 0) {
        return 'red';
      } else {
        return 'green';
      }
    }

    return '';
  }
}
