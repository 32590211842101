// core
import {APIRequest, Method} from 'src/app/core/models';

export class ListAllClientsRequest implements APIRequest {

  method: Method = Method.GET;
  path: string = '/clients/all';

  constructor() {
  }
}
