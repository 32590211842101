import {Component, Input, OnInit} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from "@angular/forms";
import {RemittanceInformationType} from "../../../modules/clients/models";
import {RemittanceInformationValue} from "../../models/remittance-information-value";
import {FormError} from "../../models/form-error";

@Component({
  selector: 'app-remittance-information-form-control',
  templateUrl: './remittance-information-form-control.component.html',
  styleUrls: ['./remittance-information-form-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: RemittanceInformationFormControlComponent
    },
  ]
})
export class RemittanceInformationFormControlComponent implements ControlValueAccessor {

  @Input() formError: FormError|null = null;
  @Input() showError: boolean = true;
  @Input() nonTouched: boolean = false;

  readonly RemittanceInformationType = RemittanceInformationType;

  onChange = (info: RemittanceInformationValue) => {
  };

  onBlur(): void {
    this.editing = false;
    this.touched = this.nonTouched && this.info?.length > 0;
  };

  info: string = '';
  type: RemittanceInformationType = RemittanceInformationType.UNSTRUCTURED;
  touched: boolean = false;
  editing: boolean = false;

  constructor() {
  }

  get isUnstructured(): boolean {
    return this.type === RemittanceInformationType.UNSTRUCTURED;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
  }

  writeValue(info: RemittanceInformationValue): void {
    this.info = info.info;
    this.type = info.type;
  }

  updateType(type: RemittanceInformationType): void {
    this.type = type;
    this.onChange({type: this.type, info: this.info});
  }

  infoUpdated($event: any) {
    this.markAsTouched();
    this.info = $event;
    this.onChange({type: this.type, info: this.info});
  }

  markAsTouched() {
    if (!this.touched) {
      this.touched = true;
    }
  }

  shouldValidate(): boolean {
    // validate only touched values when valid already set
    return this.touched && this.info?.length > 0;
  }

  isValid(): boolean {
    return this.formError === null
  }

  isInputSet(): boolean {
    return this.shouldValidate()
  }

  onInput(): void {
    this.editing = true;
  }

  isEditing(): boolean {
    return this.editing;
  }

  maxLengthAndNumericOnly(event: any): void {
    this.onInput();
    // remove non digits (and non / ) from structure message
    event.target.value = event.target.value.replace(/[^0-9\/]/g,'');
    // also limit in length to 14 characters
    event.target.value = event.target.value.substring(0,14);
  }

}
