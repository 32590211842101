// core
import { APIRequest, Method } from 'src/app/core/models';

export class UpdateAssigneeRequest implements APIRequest {
  method: Method = Method.PUT;
  path: string;
  body: { userId: string };

  constructor(clientId: string, assigneeId: string) {
    this.path = '/clients/' + clientId + '/assignee';
    this.body = { userId: assigneeId };
  }
}
