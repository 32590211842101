// Angular
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'ibanSmall' })
export class IbanSmallPipe implements PipeTransform {
  transform(value?: string): string {
    if (value && value.length > 0) {
      return value.substring(0, 4) + ' ... ' + value.substring(value.length - 4);
    } else {
      return '';
    }
  }
}
