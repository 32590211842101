// Angular
import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
// 3rd-party
import {forkJoin, Subscription} from 'rxjs';
import {filter, mergeMap, takeUntil} from 'rxjs/operators';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
// app
import {routes} from 'src/app/_nav';
import {BaseComponent} from "../../../base.component";
// shared
import {AppSettings, SortOrder} from 'src/app/shared';
import {filterNullish} from '../../../shared/utils/other/rxjs.utils';
import {
  GlobalEmitter,
  UPDATE_CLIENTS_WITH_NOTIFICATIONS_COUNT_EMITTER_KEY,
  UPDATE_ACCOUNTS_WITH_WARNING_COUNT_EMITTER_KEY,
  UPDATE_OPEN_NOTIFICATIONS_COUNT_EMITTER_KEY,
  UPDATE_PAYMENT_COUNT_EMITTER_KEY,
  UPDATE_UNLINKED_ACCOUNTS_COUNT_EMITTER_KEY
} from '../../../shared/utils/other/global-emitter';
// modules
import {BankAccountsResult, LinkedStatus} from '../../../modules/accounts/models';
import {AccountsService} from '../../../modules/accounts/service';
import {ClientsService} from '../../../modules/clients/services';
import {NotificationsService} from '../../../modules/inbox/services';
import {OfficeService} from 'src/app/modules/configuration/services';
import {OfficeStoreService} from 'src/app/modules/configuration/services/office-store.service';
import {OfficeUser} from 'src/app/modules/configuration/models';
import {PaymentsService} from "../../../modules/payments/services/payments.service";
// local
import {
  AppService,
  TranslationService,
  UserService,
  UserStoreService,
} from '../../services';
import {RoleCheckerService} from "../../services/authentication/role-checker.service";
import {Role} from "../../models/role.enum";

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
})
export class DefaultLayoutComponent extends BaseComponent implements OnInit, OnDestroy {

  readonly year = new Date().getFullYear();
  readonly Role = Role;

  countClientsWithNotifications: number = 0;
  countUnlinkedAccounts: number = 0;
  countAccountsWithWarning: number = 0;
  countUnprocessedNotifications: number = 0;
  countPayments: number = 0;
  navRoutes: string[] = [];
  dropdownList: { itemId: number; itemText: string }[] = [];
  selectedItems: { itemId: number; itemText: string }[] = [];
  dropdownSettings: IDropdownSettings = {};
  loading: boolean = true;
  officeUsers: OfficeUser[] = [];

  // subscriptions
  unprocessedCountSubscription: Subscription | null = null;

  constructor(
    private accountsService: AccountsService,
    private appService: AppService,
    private clientsService: ClientsService,
    private notificationsService: NotificationsService,
    private officeService: OfficeService,
    private officeStoreService: OfficeStoreService,
    private router: Router,
    private translationService: TranslationService,
    private userService: UserService,
    private userStoreService: UserStoreService,
    public roleChecker: RoleCheckerService,
    private paymentsService: PaymentsService
  ) {
    super();

    if (!roleChecker.isCourtUser()) {
      GlobalEmitter.of<void>(UPDATE_CLIENTS_WITH_NOTIFICATIONS_COUNT_EMITTER_KEY)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => this.getClientsWithNotificationsCount());

      GlobalEmitter.of<void>(UPDATE_UNLINKED_ACCOUNTS_COUNT_EMITTER_KEY)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => this.getUnlinkedAccountsCount());

      GlobalEmitter.of<void>(UPDATE_ACCOUNTS_WITH_WARNING_COUNT_EMITTER_KEY)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => this.getAccountsWithWarningCount());

      GlobalEmitter.of<void>(UPDATE_PAYMENT_COUNT_EMITTER_KEY)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => this.getQueuedPaymentsCount());

      GlobalEmitter.of<void>(UPDATE_OPEN_NOTIFICATIONS_COUNT_EMITTER_KEY)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => this.getUnprocessedNotificationsCount());
    }
  }

  ngOnInit(): void {
    this.initializeContent();

    if (!this.roleChecker.isCourtUser()) {
      this.navRoutes = ['dashboard', 'clients', 'accounts', 'payments', 'inbox'];
    } else {
      this.navRoutes = ['clients'];
    }
  }

  initializeContent() {
    let profile = this.userService.getProfile();
    let office = this.officeService.getOffice();
    let officeUsers = this.officeService.getUsersMinimal();

    forkJoin([profile, office, officeUsers]).subscribe((results) => {
      this.userStoreService.updateUser(results[0]);
      this.translationService.setTranslationCode(results[0].languageCode);
      this.officeStoreService.setOffice(results[1]);

      if (!this.roleChecker.isCourtUser()) {
        this.getAllCounts();
      }

      this.officeUsers = results[2];
      this.setDropdownContent(results[2]);
    });
  }

  getAllCounts(): void {
    this.getClientsWithNotificationsCount();
    this.getUnlinkedAccountsCount();
    this.getAccountsWithWarningCount();
    this.getQueuedPaymentsCount();
    this.getUnprocessedNotificationsCount();
  }

  getClientsWithNotificationsCount(): void {
    this.clientsService.countClients(true)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((count: number) => this.countClientsWithNotifications = count);
  }

  getUnlinkedAccountsCount(): void {
    const browseRequest = {
      currentPage: 1,
      pageSize: 1,
      linkedStatus: LinkedStatus.UNLINKED,
      sortColumn: 'label',
      sortDirection: SortOrder.ASC
    };

    this.accountsService.countUnlinkedBankAccounts()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((count) => this.countUnlinkedAccounts = count);
  }

  getAccountsWithWarningCount(): void {
    this.officeStoreService.currentOffice
      .pipe(
        filterNullish(),
        filter(office => office.hasXS2AFeature()),
        mergeMap(() => this.accountsService.countBankAccounts(true)),
        takeUntil(this.ngUnsubscribe)
      ).subscribe((count: number) => this.countAccountsWithWarning = count);
  }

  getQueuedPaymentsCount(): void {
    this.officeStoreService.currentOffice
      .pipe(
        filterNullish(),
        filter(office => office.hasISABEL_PI()),
        mergeMap(() => this.paymentsService.countQueuedPayments()),
        takeUntil(this.ngUnsubscribe)
      ).subscribe((count: number) => this.countPayments = count);
  }

  getUnprocessedNotificationsCount(): void {
    this.notificationsService.countUnprocessedNotifications()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((count: number) => this.countUnprocessedNotifications = count);
  }

  onTabClicked(): void {
    if (!this.roleChecker.isCourtUser()) {
      this.getAllCounts();
    }
  }

  onLogout(): void {
    this.userStoreService.logout();
    this.appService.clearStores();
    this.router.navigate([routes.LOGIN]).then();
  }

  onProfileClicked(): void {
    this.router.navigate([routes.PROFILE]).then();
  }

  onOfficeConfigurationClicked(): void {
    this.router.navigate([routes.OFFICE_CONFIGURATION]).then();
  }

  onBulkActionsClicked(): void {
    this.router.navigate([routes.BULK_ACTIONS]).then();
  }

  onArchiveClientsClicked(): void {
    this.router.navigate([routes.CLIENTS_ARCHIVED]).then();
  }

  onArchiveAccountsClicked(): void {
    this.router.navigate([routes.ACCOUNTS_ARCHIVED]).then();
  }

  initializeDropdown() {
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'itemId',
      textField: 'itemText',
      selectAllText: this.translationService.translate('selectAll'),
      unSelectAllText: this.translationService.translate('unselectAll'),
      searchPlaceholderText: this.translationService.translate('search'),
      itemsShowLimit: 1,
      allowSearchFilter: true,
    };
  }

  setDropdownContent(users: OfficeUser[]) {
    this.initializeDropdown();

    const loggedInUser = users.find((user) => user.id === this.userStoreService.getUserId());
    const previouslySelectedAssignees = window.localStorage.getItem(AppSettings.LOCAL_STORAGE_ASSIGNEE);

    if (loggedInUser) {
      this.dropdownList = [
        {
          itemId: 0,
          itemText: loggedInUser.firstName + ' ' + loggedInUser.lastName,
        },
      ];
    }

    this.dropdownList.push({
      itemId: loggedInUser ? 1 : 0,
      itemText: this.translationService.translate('CLIENTS.DETAILS.unassigned'),
    });

    users
      .filter((user) => user.id !== this.userStoreService.getUserId())
      .forEach((user, index) => {
        this.dropdownList.push({
          itemId: index + (loggedInUser ? 2 : 1),
          itemText: user.firstName + ' ' + user.lastName,
        });
      });

    if (previouslySelectedAssignees) {
      const previouslySelectedAssigneesArray = previouslySelectedAssignees.split(',');
      const officeUsersPreviouslySelected = users.filter(
        (user) => previouslySelectedAssigneesArray.findIndex((assignee) => assignee === user.id) > -1
      );

      officeUsersPreviouslySelected.forEach((officeUser) => {
        const itemInCurrentDropdown = this.dropdownList.find(
          (item) => item.itemText === officeUser.firstName + ' ' + officeUser.lastName
        );
        if (itemInCurrentDropdown) {
          this.selectedItems.push(itemInCurrentDropdown);
        }
      });

      if (previouslySelectedAssigneesArray.findIndex((assignee) => assignee === 'UNASSIGNED') > -1) {
        this.selectedItems.push({
          itemId: loggedInUser ? 1 : 0,
          itemText: this.translationService.translate('CLIENTS.DETAILS.unassigned'),
        });
      }
    }

    this.loading = false;
  }

  filterAll(users?: any): void {
    const userIds: string[] = [];

    if (users) {
      this.selectedItems = users;
    }

    this.selectedItems.forEach((item) => {
      if (item.itemText === this.translationService.translate('CLIENTS.DETAILS.unassigned')) {
        userIds.push('UNASSIGNED');
      } else {
        userIds.push(this.officeUsers.find((user) => user.firstName + ' ' + user.lastName === item.itemText)?.id ?? '');
      }
    });

    this.userStoreService.setSelectedAssignees(userIds);
    window.location.reload();
  }

  ngOnDestroy() {
    if (this.unprocessedCountSubscription) this.unprocessedCountSubscription.unsubscribe();
  }
}
