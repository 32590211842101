// Angular
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dropdown-filter',
  templateUrl: './dropdown-filter.component.html',
  styleUrls: ['./dropdown-filter.component.scss']
})
export class DropdownFilterComponent<T> {
  @Input() allSelector: boolean = false;
  @Input() allSelectorTranslation?: string;
  @Input() context: string = '';
  @Input() selectedValue: any | null = null;
  @Input() possibleValues: T[] = [];
  @Input() translateValue: boolean = true;
  @Input() textProperty?: string;
  @Input() valueProperty?: string;
  @Input() disabled: boolean = false;

  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  onValueChange(event: any) {
    this.valueChange.emit(event);
  }

  getValue(value: any): any {
    if (this.valueProperty && value[this.valueProperty]) {
      return value[this.valueProperty];
    }

    return value;
  }

  getName(value: any): any {
    if (this.textProperty && value[this.textProperty]) {
      return value[this.textProperty];
    }

    return value;
  }
}
