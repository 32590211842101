// core
import { APIRequest, Method } from 'src/app/core/models';
// local
import { ClientDetailDTO } from '../models';

export class UpdateClientRequest implements APIRequest {
  method: Method = Method.PUT;
  path: string;
  body: ClientDetailDTO;

  constructor(clientId: string, clientDetailDTO: ClientDetailDTO) {
    this.path = '/clients/' + clientId;
    this.body = clientDetailDTO;
  }
}
