<div class="dropdown">
  <select [disabled]="disabled" (change)="onValueChange($event)" required>
    <option *ngIf='allSelector' [selected]="selectedValue === 'ALL'" [value]="'ALL'">
      <ng-container *ngIf="allSelectorTranslation;else rawValue">{{allSelectorTranslation | translate}}</ng-container>
      <ng-template #rawValue>{{ 'all' | translate }}</ng-template>
    </option>
    <option
      *ngFor="let value of possibleValues"
      [selected]="getValue(value) === selectedValue"
      [value]="getValue(value)"
    >
      <ng-container *ngIf="translateValue;else rawValue">{{ context + '.' + getName(value) | translate }}</ng-container>
      <ng-template #rawValue>{{getName(value)}}</ng-template>
    </option>
  </select>
  <i class="fas fa-chevron-down"></i>
</div>
