// Angular
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-toggle-filter',
  templateUrl: './toggle-filter.component.html',
  styleUrls: ['./toggle-filter.component.scss']
})
export class ToggleFilterComponent {
  @Input() label: string = '';
  @Input() value: boolean = false;

  @Output() valueChanged: EventEmitter<void> = new EventEmitter<void>();

  onValueChanged(): void {
    this.valueChanged.emit();
  }
}
