<div
  *ngIf="loading"
  class="shared-loader center py-3"
  [ngClass]="{ full: full }"
  [ngStyle]="{ backgroundColor: backgroundColor }"
>
  <div class="spinner p-2">
    <div class="bounce1" [ngStyle]="{ backgroundColor: color, width: size, height: size }"></div>
    <div class="bounce2" [ngStyle]="{ backgroundColor: color, width: size, height: size }"></div>
    <div class="bounce3" [ngStyle]="{ backgroundColor: color, width: size, height: size }"></div>
  </div>
  <div class="p-2" *ngIf="title || subtitle" [ngStyle]="{ color: color }">
    <span *ngIf="title" class="h2">{{ title }}</span>
    <br />
    <span *ngIf="subtitle">{{ subtitle }}</span>
  </div>
</div>
<span [ngStyle]="loading ? { display: 'none' } : {}">
  <ng-content></ng-content>
</span>
