// Angular
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'structuredRemittance' })
export class StructuredRemittancePipe implements PipeTransform {
  transform(remittance?: string): string {
    if (remittance && remittance.length > 0) {
      const letters = remittance.replace(/\//g, '').replace(/\D/g,'').split('');

      if (letters.length > 3) {
        letters.splice(3, 0, '/')
      }

      if (letters.length > 8) {
        letters.splice(8, 0, '/')
      }

      return letters.join('');
    } else {
      return '';
    }
  }
}
