export class AppSettings {
  public static LIST_SIZE = 20;

  // local storage consts
  public static LOCAL_STORAGE_TOKEN_KEY = 'userToken';
  public static LOCAL_STORAGE_ROLES_KEY = 'roles';
  public static LOCAL_STORAGE_LOCALE = 'locale';
  public static LOCAL_STORAGE_ASSIGNEE = 'selectedAssignees'

  // euro code
  public static EUR_CODE = 'EUR';

  // untracked error codes
  public static UNTRACKED_ERROR_STATUSES = [401];

  // excludeAssignees API
  public static EXCLUDE_ASSIGNEES_FROM_API = 'excludeAssigneesFromApi'
}
