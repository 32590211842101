<ng-container *ngIf='!loading'>
  <ng-container *ngIf='!maintenanceMode; else maintenance'>
    <router-outlet></router-outlet>
  </ng-container>
  <ng-template #maintenance>
    <div class="app-body">
      <main class="main d-flex align-items-center">
        <div class="container">
          <div class="row">
            <div class="col-md-8 mx-auto">
              <div class="card-group">
                <div class="card p-4">
                  <div class="card-body">
                    <h1>{{ 'MAINTENANCE.title' | translate }}</h1>
                    <div class='maintenance-content'>
                      <h3>{{ 'MAINTENANCE.subtitle' | translate }}</h3>
                      <i class='fas fa-cogs'></i>
                      <p>{{ 'MAINTENANCE.info' | translate }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </ng-template>
</ng-container>
