// Angular
import {KeyValue} from '@angular/common';

export enum Method {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}

export interface APIRequest {
  path: string;
  method: Method;
  body?: any;
  requestParams?: KeyValue<string, string>[];
  responseType?: string;
  acceptHeader?: string;
}
