// Angular
import { Pipe, PipeTransform } from '@angular/core';
// modules
import { Client } from '../../../modules/clients/models';

@Pipe({ name: 'clientColor' })
export class ClientColorPipe implements PipeTransform {
  transform(client: Client): string {
    return (client.clientBankAccounts ?? []).every((ba) => ba.currentBalance >= 0) &&
      (client.officeBankAccounts ?? []).every((ba) => ba.currentBalance >= 0)
      ? 'green'
      : 'red';
  }
}
