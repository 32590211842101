// core
import { APIRequest, Method } from 'src/app/core/models';
// local
import { GenerateReportDTO } from '../models';

export class GenerateReportRequest implements APIRequest {
  method: Method = Method.POST;
  path: string;
  body: GenerateReportDTO;

  constructor(clientId: string, generateReportDTO: GenerateReportDTO) {
    this.path = '/clients/' + clientId + '/reports/request-report';
    this.body = generateReportDTO;
  }
}
