import { Address, BrowseRequest, ClientType, LanguageCode, PagingResponse } from '../../../shared';
import {Role} from "../../../core/models/role.enum";

export class Office {
  id: string;
  name: string;
  clientTypes: ClientType[];
  defaultJusticePeace: JusticePeace;
  defaultLabourCourt: LabourCourt;
  languageCode: LanguageCode;
  address: Address;
  ibans: string[];
  hasLogo: boolean;
  labourCourts: LabourCourt[];
  features: OfficeFeature[];
  allowImportXs2a: boolean;
  dummy: boolean;

  constructor(data: any) {
    this.id = data.id;
    this.name = data.name;
    this.clientTypes = data.clientTypes;
    this.defaultJusticePeace = data.defaultJusticePeace;
    this.defaultLabourCourt = data.defaultLabourCourt;
    this.languageCode = data.languageCode;
    this.address = data.address;
    this.ibans = data.ibans;
    this.hasLogo = data.hasLogo;
    this.labourCourts = data.labourCourts;
    this.features = data.features;
    this.allowImportXs2a = data.allowImportXs2a;
    this.dummy = data.dummy;
  }

  hasXS2AFeature(): boolean {
    return this.hasFeature(OfficeFeature.XS2A);
  }

  hasISABEL_AISFeature(): boolean {
    return this.hasFeature(OfficeFeature.ISABEL_AIS);
  }

  hasISABEL_PI(): boolean {
    return this.hasFeature(OfficeFeature.ISABEL_PI);
  }

  hasFeature(feature: OfficeFeature): boolean {
    return this.features?.includes(feature) || false;
  }

}

export enum OfficeFeature {
  XS2A = 'XS2A',
  ISABEL_AIS = 'ISABEL_AIS',
  ISABEL_PI = 'ISABEL_PI'
}

export interface UpdateOfficeDTO {
  name: string;
  languageCode: LanguageCode;
  defaultLabourCourtId: string;
  address: Address;
}

export interface UpdateCourtOfficeDTO {
  name: string;
  languageCode: LanguageCode;
  address: Address;
}

export interface ClientBankAccountSettings {
  balance: boolean;
  notifications: boolean;
}

export interface JusticePeace {
  id: string;
  district: string;
  name: string;
}

export interface LabourCourt {
  id: string;
  name: string;
}

export interface UsersBrowseRequest extends BrowseRequest {
  userType: UserType;
}

export enum UserType {
  ALL = 'ALL',
  BLOCKED = 'BLOCKED',
  UNBLOCKED = 'UNBLOCKED',
}

export interface UsersResult extends PagingResponse<OfficeUser> {
  results: OfficeUser[];
}

export interface OfficeUser {
  id: string;
  firstName: string;
  lastName: string;
  email?: string;
  blocked?: boolean;
  roles: Role[];
}

export interface UserDetailDTO {
  firstName: string;
  lastName: string;
  email: string;
  initialPassword?: string;
  roles: Role[];
}
