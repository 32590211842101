import {APIRequest, Method} from '../../../core/models';
import {UpdateTransactionTags} from '../models/update-transaction-tags.model';

export class UpdateTransactionTagsRequest implements APIRequest {

  method = Method.PUT;
  path: string;
  body: any;

  constructor(bankAccountId: string, transactionId: string, request: UpdateTransactionTags) {
    this.path = `/bank-accounts/${bankAccountId}/transactions/${transactionId}/tags`;
    this.body = request;
  }
}
