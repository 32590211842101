// Angular
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'iban' })
export class IbanPipe implements PipeTransform {
  transform(value?: string|null): string {
    if (value && value.length > 0) {
      const numbers: string[] = value.replace(/ /g, '').split('');

      for (let i = numbers.length - 1; i > 3; i--) {
        if (i % 4 === 0) {
          numbers.splice(i, 0, ' ');
        }
      }

      return numbers.join('');
    } else {
      return '';
    }
  }
}
