<div class="toggle">
  <label
    for="value"
    [class.unchecked]="!value"
  >
    {{ label }}
  </label>
  <div class="switch switch-3d switch-primary" (click)="onValueChanged()">
    <input
      class="switch-input"
      id="value"
      type="checkbox"
      [checked]="value"
    />
    <span class="switch-slider"></span>
  </div>
</div>
