// core
import { APIRequest, Method } from 'src/app/core/models';
// local
import { ClientDetailDTO } from '../models';

export class CreateClientRequest implements APIRequest {
  method: Method = Method.POST;
  path: string = '/clients';
  body: ClientDetailDTO;

  constructor(clientDetailDTO: ClientDetailDTO) {
    this.body = clientDetailDTO;
  }
}
