<div class="confirm-popup-wrapper" (click)='onClosePopup()'>
  <div class="confirm-popup" (click)="$event.stopPropagation()">
    <h3>{{ title }}</h3>
    <p [innerHTML]='text'></p>
    <div class="buttons">
      <div class="btn btn-primary px-4 ml-2" (click)='onConfirmClicked()'>
        {{ confirmBtnText | translate }}
      </div>
      <div class="btn btn-secondary px-4" (click)="onClosePopup()">
        {{ 'cancel' | translate }}
      </div>
    </div>
  </div>
</div>
