import {Directive, OnDestroy} from "@angular/core";
import {Subject} from "rxjs";
import {AbstractControl, Form, FormGroup} from "@angular/forms";
import {FormError} from "./shared/models/form-error";

@Directive()
export abstract class BaseComponent implements OnDestroy {

    protected ngUnsubscribe: Subject<boolean> = new Subject<boolean>();

    protected formErrors: Map<string, FormError|null> = new Map<string, FormError | null>();

    ngOnDestroy(): void {
        this.ngUnsubscribe.next(true);
        this.ngUnsubscribe.complete();
    }

    hasFormError(field: string): boolean {
      return this.formErrors.has(field) && this.formErrors.get(field) !== null;
    }

    getFormError(field: string): FormError|null {
      return this.hasFormError(field) ? this.formErrors.get(field)! : null;
    }

    protected checkForErrors(form: FormGroup, possibleFormErrors: Map<string, FormError[]>): void {
      const errors = new Map<string, FormError|null>();

      for (const [key, value] of possibleFormErrors) {
        const formControl = form.get(key);

        if (formControl && formControl.dirty && formControl.invalid) {
          for (const errorKey in formControl.errors) {
            const formError = possibleFormErrors.get(key)!
              .find(formError => formError.errorKey === errorKey);

            if (formError) {
              errors.set(key, formError);
              break;
            }
          }
        }
      }

      this.formErrors = errors;
    }
}
