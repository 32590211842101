import { Component, Input, OnInit } from '@angular/core';
import { BankAccount, BankAccountStatus } from '../../models/bank-account.model';
import { getBankAccountStatus } from '../../utils/other/bank-account.utils';

@Component({
  selector: 'app-bank-account-status-icon',
  templateUrl: './bank-account-status-icon.component.html',
  styleUrls: ['./bank-account-status-icon.component.scss']
})
export class BankAccountStatusIconComponent implements OnInit {
  @Input() bankAccount?: BankAccount;

  public readonly getBankAccountStatus = getBankAccountStatus;
  public readonly BankAccountStatus = BankAccountStatus;

  constructor() { }

  ngOnInit(): void {
  }

}
