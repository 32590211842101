import {APIRequest, Method} from "../../../core/models";
import {KeyValue} from "@angular/common";
import {AppSettings} from "../../../shared";
import {EditPayment, PaymentBrowseRequest} from "../models/payment.model";

export class DeletePaymentRequest implements APIRequest {

  method: Method = Method.DELETE;
  path: string;

  constructor(id: string) {
    this.path = `/payments/${id}`;
  }
}
