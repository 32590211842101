import {APIRequest, Method} from 'src/app/core/models';
import {UpdateTransactionCategory} from "../models/update-transaction-category";

export class UpdateTransactionRequestForAccountRequest implements APIRequest {

  method: Method = Method.PUT;
  path: string;
  body: any;

  constructor(bankAccountId: string, transactionId: string, request: UpdateTransactionCategory) {
    this.path = '/bank-accounts/' + bankAccountId + '/transactions/' + transactionId + '/categories/office';
    this.body = request;
  }
}


