// core
import { APIRequest, Method } from 'src/app/core/models';
// local
import { ReportType } from '../models';

export class FormInfoForReportTypeRequest implements APIRequest {
  method: Method = Method.GET;
  path: string;

  constructor(clientId: string, reportType: ReportType) {
    this.path = '/clients/' + clientId + '/available-report-types/' + reportType + '/form-info';
  }
}
