// core
import { APIRequest, Method } from 'src/app/core/models';

export class ArchiveAccountRequest implements APIRequest {
  method: Method = Method.PUT;
  path: string;

  constructor(accountId: string) {
    this.path = '/bank-accounts/' + accountId + '/archive';
  }
}
