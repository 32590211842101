// Angular
import { Injectable } from '@angular/core';
// shared
import { User } from 'src/app/shared';
// local
import { ApiService } from '../api/api.service';
import { ProfileRequest } from '../../api-requests';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private apiService: ApiService) {}

  getProfile() {
    return this.apiService.execute<User>(new ProfileRequest());
  }
}
