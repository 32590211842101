import { Injectable } from '@angular/core';
import {ApiService} from "../../../core/services";
import {EditPayment, Payment, PaymentBrowseRequest, PaymentsResult} from "../models/payment.model";
import {Observable} from "rxjs";
import {ListPaymentsRequest} from "../api-requests/list-payments.request";
import {CreatePaymentRequest} from "../api-requests/create-payment.request";
import {UpdatePaymentRequest} from "../api-requests/update-payment.request";
import {DeletePaymentRequest} from "../api-requests/delete-payment.request";
import {SubmitPaymentsRequest} from "../api-requests/submit-payments.request";
import {CountQueuedPaymentsRequest} from "../api-requests/count-queued-payments.request";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {

  constructor(
    private apiService: ApiService
  ) { }

  browsePayments(paymentsBrowseRequest: PaymentBrowseRequest, fetchAll: boolean = false): Observable<PaymentsResult> {
    return this.apiService.execute<PaymentsResult>(new ListPaymentsRequest(paymentsBrowseRequest, fetchAll));
  }

  createPayment(payment: EditPayment): Observable<Payment> {
    return this.apiService.execute<Payment>(new CreatePaymentRequest(payment));
  }

  public updatePayment(id: string, payment: EditPayment): Observable<Payment> {
    return this.apiService.execute<Payment>(new UpdatePaymentRequest(id, payment));
  }

  public deletePayment(id: string): Observable<void> {
    return this.apiService.execute<void>(new DeletePaymentRequest(id));
  }

  public submitPayments(payments: Payment[]): Observable<void> {
    return this.apiService.execute<void>(new SubmitPaymentsRequest(payments));
  }

  public countQueuedPayments(): Observable<number> {
    return this.apiService.execute<PaymentsResult>(new CountQueuedPaymentsRequest())
      .pipe(map(result => result.resultsCount));
  }
}
