<div class="query">
  <input
    autocomplete="off"
    class="form-control"
    name="filter"
    type="text"
    [(ngModel)]="query"
    [disabled]="disabled"
    [placeholder]="placeholder"
    [value]="isIban ? (query | iban | uppercase) : query"
    (ngModelChange)="onQueryChanged($event)"
  />
  <i class="fas fa-search"></i>
</div>
