// Angular
import { Pipe, PipeTransform } from '@angular/core';
// models
import { ClientType } from '../../models/client.model';

@Pipe({ name: 'clientTypeIcon' })
export class ClientTypeIconPipe implements PipeTransform {
  transform(clientType?: ClientType): string {
    switch (clientType) {
      case ClientType.DEBT_MEDIATION: return "fa-hands-helping";
      case ClientType.FINANCIAL_MANAGEMENT: return "fa-umbrella";
      case ClientType.PCSW: return "fa-hands";
      case ClientType.BUDGET_GUIDANCE: return "fa-chart-pie";
      case ClientType.SOCIAL_RENT: return "fa-building";
      case ClientType.RENT: return "fa-home";
      default: return "fa-user";
    }
  }
}
