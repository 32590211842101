// core
import { APIRequest, Method } from 'src/app/core/models';

export class OverviewDebtDossiersRequest implements APIRequest {
  method: Method = Method.GET;
  path: string;
  responseType: string = 'blob';

  constructor(labourCourtId: string) {
    this.path = '/courts/' + labourCourtId + '/reports/active-debt-dossiers';
  }
}
