// shared
// local
import {FinancialInstitution} from './financial-institution.model';
import {BankAccountType, BasicBankAccount} from '../../../shared';

export interface AiarInfo {
  activeIbans: string[];
  isabelAccounts?: AiarIsabelAccount[];
  customerReference?: string;
  financialInstitution: FinancialInstitution;
  ibanInputAmount: IbanInputAmount;
  preferredAuthorizationModel: AuthorizationModel;
  requiresCustomerReference: boolean;
}

export enum AuthorizationModel {
  DETAILED = 'DETAILED',
  FINANCIAL_INSTITUTION_OFFERED = 'FINANCIAL_INSTITUTION_OFFERED',
  FINANCIAL_INSTITUTION_OFFERED_OR_GLOBAL = 'FINANCIAL_INSTITUTION_OFFERED_OR_GLOBAL',
  GLOBAL = 'GLOBAL',
  SINGLE_WITH_IBAN = 'SINGLE_WITH_IBAN',
  SINGLE_WITHOUT_IBAN = 'SINGLE_WITHOUT_IBAN',
  UNKNOWN = 'UNKNOWN',
}

export enum IbanInputAmount {
  NONE = 'NONE',
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE',
}

export interface AiarIsabelAccount {
  iban: string;
  label: string;
  type?: BankAccountType;
}

export interface AiarCreation {
  financialInstitutionId: string;
  financialInstitutionCustomerReference?: string;
  requestedAccountReferences?: string[];
}

export interface AiarCreationResponse {
  redirectUri: string;
}

export interface AiarAuthorizationDTO {
  queryParameters: Map<string, string>;
}

export interface AiarAuthorizationResponse {
  bankAccounts?: BasicBankAccount[];
  nextRedirectUri?: string;
}
