// Angular
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'camelToKebab' })
export class CamelToKebabPipe implements PipeTransform {
  transform(value: string): string {
    return value
      .split('')
      .map((letter, idx) => {
        return letter.toUpperCase() === letter ? `${idx !== 0 ? '-' : ''}${letter.toLowerCase()}` : letter;
      })
      .join('');
  }
}
