// core
import { APIRequest, Method } from 'src/app/core/models';

export class DownloadDocumentRequest implements APIRequest {
  method: Method = Method.GET;
  path: string;
  responseType: string = 'blob';

  constructor(clientId: string, documentId: string) {
    this.path = '/clients/' + clientId + '/documents/' + documentId;
  }
}
