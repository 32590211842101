import {APIRequest, Method} from "../../../core/models";
import {Document} from "../models";

export class UpdateDocumentRequest implements APIRequest {
  method: Method = Method.PATCH;
  path: string;
  body: any;

  constructor(clientId: string, document: Document) {
    this.path = '/clients/' + clientId + '/documents/' + document.id;
    this.body = {name: document.name, hide: document.hidden};
  }
}
