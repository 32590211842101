import {APIRequest, Method} from "../../../core/models";
import {KeyValue} from "@angular/common";
import {AppSettings} from "../../../shared";
import {EditPayment, PaymentBrowseRequest} from "../models/payment.model";

export class UpdatePaymentRequest implements APIRequest {

  method: Method = Method.PUT;
  path: string;
  body: EditPayment;

  constructor(id: string, payment: EditPayment) {
    this.path = `/payments/${id}`;
    this.body = payment;
  }
}
