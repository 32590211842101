// Angular
import {Injectable} from '@angular/core';
// 3rd-party
import {Observable} from 'rxjs';
import {map} from "rxjs/operators";
// core
import {ApiService} from 'src/app/core/services';
import {RoleCheckerService} from "../../../core/services/authentication/role-checker.service";
// local
import {
  JusticePeace,
  LabourCourt,
  Office,
  OfficeUser,
  UpdateCourtOfficeDTO,
  UpdateOfficeDTO,
  UserDetailDTO,
  UsersBrowseRequest,
  UsersResult
} from '../models';
import {
  AddUserRequest,
  OverviewDebtDossiersRequest,
  ListLabourCourtRequest,
  ListUsersMinimalRequest,
  ListUsersRequest,
  LogoRequest,
  OfficeDetailsRequest,
  UpdateCourtOfficeRequest,
  UpdateLogoRequest,
  UpdateOfficeRequest,
  UpdateUserRequest,
  UserDetailRequest
} from '../api-requests';
import {ListJusticesPeaceRequest} from '../api-requests/list-justices-peace.request';

@Injectable({
  providedIn: 'root',
})
export class OfficeService {
  constructor(
    private apiService: ApiService,
    private roleChecker: RoleCheckerService
  ) {}

  getOffice(): Observable<Office> {
    return this.apiService.execute<any>(new OfficeDetailsRequest())
      .pipe(map(data => new Office(data)));
  }

  getJusticesPeace(): Observable<JusticePeace[]> {
    return this.apiService.execute<JusticePeace[]>(new ListJusticesPeaceRequest());
  }

  getLabourCourts(): Observable<LabourCourt[]> {
    return this.apiService.execute<LabourCourt[]>(new ListLabourCourtRequest());
  }

  updateOffice(updateOfficeDTO: UpdateOfficeDTO|UpdateCourtOfficeDTO): Observable<Office> {
    return this.apiService.execute<any>(
      this.roleChecker.isCourtUser() ?
        new UpdateCourtOfficeRequest(updateOfficeDTO as UpdateCourtOfficeDTO) :
        new UpdateOfficeRequest(updateOfficeDTO as UpdateOfficeDTO)
    ).pipe(map(data => new Office(data)));
  }

  getLogo(): Observable<Blob> {
    return this.apiService.execute<Blob>(new LogoRequest());
  }

  updateLogo(formData: FormData): Observable<Office> {
    return this.apiService.execute<any>(new UpdateLogoRequest(formData))
      .pipe(map(data => new Office(data)));
  }

  getUsers(usersBrowseRequest: UsersBrowseRequest): Observable<UsersResult> {
    return this.apiService.execute<UsersResult>(new ListUsersRequest(usersBrowseRequest));
  }

  getUsersMinimal(): Observable<OfficeUser[]> {
    return this.apiService.execute<OfficeUser[]>(new ListUsersMinimalRequest());
  }

  addUser(userDetailDTO: UserDetailDTO): Observable<OfficeUser> {
    return this.apiService.execute<OfficeUser>(new AddUserRequest(userDetailDTO));
  }

  updateUser(userDetailDTO: UserDetailDTO, userId: string): Observable<OfficeUser> {
    return this.apiService.execute<OfficeUser>(new UpdateUserRequest(userDetailDTO, userId));
  }

  getUser(userId: string): Observable<OfficeUser> {
    return this.apiService.execute<OfficeUser>(new UserDetailRequest(userId));
  }

  downloadOverviewDebtDossiers(labourCourtId: string): Observable<Blob> {
    return this.apiService.execute<Blob>(new OverviewDebtDossiersRequest(labourCourtId));
  }
}
