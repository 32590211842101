// Angular
import {KeyValue} from '@angular/common';
// core
import {APIRequest, Method} from 'src/app/core/models';

// shared

export class ListAllPaymentBankAccountsRequest implements APIRequest {

  method: Method = Method.GET;
  path: string = '/payment-accounts';
  requestParams: KeyValue<string, string>[] = [];

  constructor(forClientId?: string) {
    if (forClientId) {
      this.requestParams.push({ key: 'clientId', value: forClientId });
    }
  }
}
