// core
import {APIRequest, Method} from 'src/app/core/models';

export class ListOfficeNamesRequest implements APIRequest {
  method: Method = Method.GET;
  path: string;

  constructor() {
    this.path = '/offices';
  }
}
