// core
import { APIRequest, Method } from 'src/app/core/models';

export class UnlinkClientRequest implements APIRequest {
  method: Method = Method.DELETE;
  path: string;

  constructor(bankAccountId: string, clientId: string) {
    this.path = '/bank-accounts/' + bankAccountId + '/clients/' + clientId;
  }
}
