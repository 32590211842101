// core
import { APIRequest, Method } from 'src/app/core/models';
// local
import { PasswordUpdateDTO } from '../models';

export class UpdatePasswordRequest implements APIRequest {
  method: Method = Method.PUT;
  path: string;
  body: PasswordUpdateDTO;

  constructor(passwordUpdateDTO: PasswordUpdateDTO) {
    this.path = '/auth/password';
    this.body = passwordUpdateDTO;
  }
}
