import {BankAccount, BrowseRequest, PagingResponse} from '../../../shared';
import {SimpleClient} from '../../../shared/models/simple-client';
import {SortRequest} from "../../../shared/models/sort-request.model";
import {RemittanceInformationType} from "../../clients/models";


export enum PaymentStatus {
  NOT_SUBMITTED = 'NOT_SUBMITTED',
  SUBMITTED = 'SUBMITTED',
  NOT_PROCESSED = 'NOT_PROCESSED',
  PROCESSED_WARNING = 'PROCESSED_WARNING',
  PROCESSED_ERROR = 'PROCESSED_ERROR',
  PROCESSED_OK = 'PROCESSED_OK'
}

export interface Payment {
  id: string|null;
  bankAccount?: BankAccount;
  client?: SimpleClient;
  counterpartName?: string;
  counterpartReference: string;
  amount: number;
  formattedAmount: string;
  remittanceInformation?: string;
  remittanceInformationType?: RemittanceInformationType;
  executionDate?: string;
  status: PaymentStatus;
}

export interface PaymentsResult extends PagingResponse<Payment> {
  results: Payment[];
}

export interface PaymentBrowseRequest extends BrowseRequest, SortRequest {
  status?: PaymentStatus
}

export interface EditPayment {
  accountId: string;
  amount: number;
  counterpartName: string;
  counterpartReference: string;
  remittanceInformationType: RemittanceInformationType;
  remittanceInformation: string;
  executionDate?: Date
}
