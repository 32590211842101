export interface BulkAction {
  icon: string;
  label: string;
  type: ActionType;
}

export enum ActionType {
  SEND_EMAIL = 'SEND_EMAIL',
}

export interface BulkActionDetail {
  totalSteps: number;
  steps: BulkActionStep[];
}

export interface BulkActionStep {
  type: BulkActionStepType;
  title: string;
  label: string;
  hasNextButton?: boolean;
  hasPreviousButton?: boolean;
  hasCompleteButton?: boolean;
  hasSendButton?: boolean;
}

export enum BulkActionStepType {
  BASIC = 'BASIC',
}
