// Angular
import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-password-field',
  templateUrl: './password-field.component.html',
  styleUrls: ['./password-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi:true,
      useExisting: PasswordFieldComponent
    }
  ]
})
export class PasswordFieldComponent implements ControlValueAccessor {
  password: string = '';
  revealPassword: boolean = false;

  onChange = (password: string) => {};
  onTouched = () => {};

  constructor() { }

  onPasswordChangeEvent(event: Event) {
    const element = event.target as HTMLInputElement;
    this.onChange(element.value);
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  writeValue(password: string): void {
    this.password = password;
  }
}
