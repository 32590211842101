// core
import { APIRequest, Method } from 'src/app/core/models';

export class UpdateLogoRequest implements APIRequest {
  method: Method = Method.PUT;
  path: string = '/office/logo';
  body: FormData;

  constructor(formData: FormData) {
    this.body = formData;
  }
}
