// Angular
import {KeyValue} from '@angular/common';
// core
import {APIRequest, Method} from 'src/app/core/models';
// shared
import {AppSettings} from 'src/app/shared';
// local
import {BankAccountsBrowseRequest} from '../models';

export class ListBankAccountsRequest implements APIRequest {


  method: Method = Method.GET;
  path: string = '/bank-accounts';
  requestParams: KeyValue<string, string>[];

  size: number = AppSettings.LIST_SIZE;

  constructor(bankAccountsBrowseRequest: BankAccountsBrowseRequest) {
    this.requestParams = [
      { key: 'page', value: bankAccountsBrowseRequest.currentPage.toString() },
      { key: 'size', value: bankAccountsBrowseRequest.pageSize?.toString() || this.size.toString() },
      { key: 'sortOrder', value: bankAccountsBrowseRequest.sortDirection},
      { key: 'sortField', value: bankAccountsBrowseRequest.sortColumn},
    ];

    if (bankAccountsBrowseRequest.query) {
      this.requestParams.push({ key: 'query', value: bankAccountsBrowseRequest.query });
    }

    if (bankAccountsBrowseRequest.financialInstitutionId !== 'ALL') {
      this.requestParams.push({ key: 'financialInstitutionId', value: bankAccountsBrowseRequest.financialInstitutionId })
    }

    if (bankAccountsBrowseRequest.onlyWithWarning) {
      this.requestParams.push({
        key: 'onlyWithWarning',
        value: bankAccountsBrowseRequest.onlyWithWarning.toString(),
      });
    }

    if (bankAccountsBrowseRequest.linkedStatus) {
      this.requestParams.push({
        key: 'linkedStatus',
        value: bankAccountsBrowseRequest.linkedStatus,
      });
    }

    if (bankAccountsBrowseRequest.excludeAssigneesFromApi) {
      this.requestParams.push({
        key: AppSettings.EXCLUDE_ASSIGNEES_FROM_API,
        value: bankAccountsBrowseRequest.excludeAssigneesFromApi.toString(),
      });
    }
  }
}
