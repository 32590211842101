// core
import { APIRequest, Method } from 'src/app/core/models';

export class SendWelcomeMailRequest implements APIRequest {
  method: Method = Method.POST;
  path: string;

  constructor(clientId: string) {
    this.path = '/clients/' + clientId + '/referral-code-mail';
  }
}
