// angular
import {Injectable} from '@angular/core';
// 3rd-party
import {Observable, of} from 'rxjs';
// core
import {ApiService} from 'src/app/core/services';
// local
import {
  AddDocumentsRequest,
  ArchiveClientRequest,
  AvailableReportTypesRequest,
  BrowseDocumentsRequest,
  ClientDetailsRequest,
  ClientListNotificationsRequest,
  CountClientsRequest,
  CreateClientRequest,
  DownloadDocumentRequest,
  DownloadReportRequest,
  FormInfoForReportTypeRequest,
  GenerateReportRequest,
  ListArchivedClientsRequest,
  ListClientReportsRequest,
  ListClientsRequest,
  RestoreClientRequest,
  SendWelcomeMailRequest,
  UpdateAssigneeRequest,
  UpdateClientRequest,
} from '../api-requests';
import {
  AvailableReportTypes,
  Client,
  ClientDetailDTO,
  ClientDocumentsBrowseRequest,
  ClientDocumentsResult, ClientReferral,
  ClientReport,
  ClientReportsBrowseRequest,
  ClientReportsResult,
  ClientsBrowseRequest,
  ClientsResult, Creditor,
  Document,
  GenerateReportDTO,
  ReportFormInfo,
  ReportType,
} from '../models';
import {BankAccountType, ClientType, NotificationsBrowseRequest, NotificationsResult, SortOrder} from '../../../shared';
import {UpdateDocumentRequest} from "../api-requests/update-document-request";
import {DeleteDocumentRequest} from "../api-requests/delete-document-request";
import {ClientBankAccountTypesRequest} from "../api-requests/client-bank-account-types.request";
import {OfficeName} from "../models/office-name";
import {ListOfficeNamesRequest} from "../api-requests/list-office-names.request";
import {ClientReferralCodesRequest} from "../api-requests/client-referral-codes.request";
import {map} from "rxjs/operators";
import {SimpleClient} from "../../../shared/models/simple-client";
import {ListAllClientsRequest} from "../api-requests/list-all-clients.request";
import {DeleteShareDocumentRequest, ShareDocumentRequest} from "../api-requests/share-document-request";
import {CreditorPaymentsRequest} from "../api-requests/creditor-payments.request";
import {CreditorPayments} from "../models/creditor-payments";
import {CreateCreditorPaymentsRequest} from "../api-requests/create-creditor-payments.request";
import {BatchPayments} from "../models/batch-payments";
import {DeleteReportRequest} from "../api-requests/delete-report-request";
import {ReusableCreditorDTO} from "../models/reusable-creditor.dto";
import {ListReusableCreditorRequest} from "../api-requests/list-reusable-creditor.request";

@Injectable({providedIn: 'root'})
export class ClientsService {
  constructor(private apiService: ApiService) {
  }

  getClients(clientsBrowseRequest: ClientsBrowseRequest): Observable<ClientsResult> {
    return this.apiService.execute<ClientsResult>(new ListClientsRequest(clientsBrowseRequest));
  }

  getAllClients(): Observable<SimpleClient[]> {
    return this.apiService.execute<SimpleClient[]>(new ListAllClientsRequest());
  }

  getArchivedClients(clientsBrowseRequest: ClientsBrowseRequest): Observable<ClientsResult> {
    return this.apiService.execute<ClientsResult>(new ListArchivedClientsRequest(clientsBrowseRequest));
  }

  getClient(clientId: string): Observable<Client> {
    return this.apiService.execute<Client>(new ClientDetailsRequest(clientId))
      .pipe(map(client => this.sortAccountsAndCreditors(client)));
  }

  getClientBankAccountTypes(clientType: ClientType): Observable<BankAccountType[]> {
    return this.apiService.execute<BankAccountType[]>(new ClientBankAccountTypesRequest(clientType));
  }

  createClient(clientDetailDTO: ClientDetailDTO): Observable<Client> {
    return this.apiService.execute<Client>(new CreateClientRequest(clientDetailDTO));
  }

  updateClient(clientId: string, clientDetailDTO: ClientDetailDTO): Observable<Client> {
    return this.apiService.execute<Client>(new UpdateClientRequest(clientId, clientDetailDTO));
  }

  countClients(onlyWarning: boolean): Observable<number> {
    return this.apiService.execute<number>(new CountClientsRequest(onlyWarning));
  }

  getNotificationsForClient(
    clientId: string,
    notificationsBrowseRequest: NotificationsBrowseRequest
  ): Observable<NotificationsResult> {
    return this.apiService.execute<NotificationsResult>(
      new ClientListNotificationsRequest(clientId, notificationsBrowseRequest)
    );
  }

  generateReport(clientId: string, generateReportDTO: GenerateReportDTO): Observable<ClientReport> {
    return this.apiService.execute<ClientReport>(new GenerateReportRequest(clientId, generateReportDTO));
  }

  listReports(
    clientId: string,
    clientReportsBrowseRequest: ClientReportsBrowseRequest
  ): Observable<ClientReportsResult> {
    return this.apiService.execute<ClientReportsResult>(
      new ListClientReportsRequest(clientId, clientReportsBrowseRequest)
    );
  }

  downloadReport(clientId: string, reportId: string): Observable<Blob> {
    return this.apiService.execute<Blob>(new DownloadReportRequest(clientId, reportId));
  }

  deleteReport(clientId: string, reportId: string): Observable<void> {
    return this.apiService.execute<void>(new DeleteReportRequest(clientId, reportId));
  }

  browseDocuments(
    clientId: string,
    clientDocumentsBrowseRequest: ClientDocumentsBrowseRequest
  ): Observable<ClientDocumentsResult> {
    return this.apiService.execute<ClientDocumentsResult>(
      new BrowseDocumentsRequest(clientId, clientDocumentsBrowseRequest)
    );
  }

  addDocuments(formData: FormData, clientId: string): Observable<Document> {
    return this.apiService.execute<Document>(new AddDocumentsRequest(clientId, formData));
  }

  updatedDocument(clientId: string, document: Document): Observable<void> {
    return this.apiService.execute<void>(new UpdateDocumentRequest(clientId, document));
  }

  deleteDocument(clientId: string, document: Document): Observable<void> {
    return this.apiService.execute<void>(new DeleteDocumentRequest(clientId, document));
  }

  shareDocument(clientId: string, document: Document, share: boolean): Observable<void> {
    if (share) {
      return this.apiService.execute<void>(new ShareDocumentRequest(clientId, document));
    } else {
      return this.apiService.execute<void>(new DeleteShareDocumentRequest(clientId, document));
    }
  }

  updateAssignee(clientId: string, assigneeId: string): Observable<void> {
    return this.apiService.execute<void>(new UpdateAssigneeRequest(clientId, assigneeId));
  }

  getAvailableReportTypes(clientId: string): Observable<AvailableReportTypes> {
    return this.apiService.execute<AvailableReportTypes>(new AvailableReportTypesRequest(clientId));
  }

  getFormInfoForReportType(clientId: string, reportType: ReportType): Observable<ReportFormInfo> {
    return this.apiService.execute<ReportFormInfo>(new FormInfoForReportTypeRequest(clientId, reportType));
  }

  sendWelcomeMail(clientId: string): Observable<Client> {
    return this.apiService.execute<Client>(new SendWelcomeMailRequest(clientId));
  }

  archive(clientId: string): Observable<void> {
    return this.apiService.execute<void>(new ArchiveClientRequest(clientId));
  }

  restoreClient(clientId: string): Observable<void> {
    return this.apiService.execute<void>(new RestoreClientRequest(clientId));
  }

  getOfficeNames(): Observable<OfficeName[]> {
    return this.apiService.execute<OfficeName[]>(new ListOfficeNamesRequest())
  }

  getClientReferralCodes(clientId: string): Observable<string[]> {
    return this.apiService.execute<ClientReferral[]>(new ClientReferralCodesRequest(clientId))
      .pipe(
        map(codes => codes.map(clientReferral => clientReferral.code))
      );
  }

  private sortAccountsAndCreditors(client: Client): Client {
    // TODO: Sort accounts part of ROOV-532

    client.creditors = client.creditors.sort((a: Creditor, b: Creditor) => a.position - b.position);

    return client;
  }

  getCreditorPayments(clientId: string): Observable<CreditorPayments> {
    return this.apiService.execute<CreditorPayments>(new CreditorPaymentsRequest(clientId))
  }

  submitCreditorPayments(payments: BatchPayments): Observable<void> {
    return this.apiService.execute<void>(new CreateCreditorPaymentsRequest(payments))
  }

  listReusableCreditors(officeId: string): Observable<ReusableCreditorDTO[]> {
    return this.apiService.execute<ReusableCreditorDTO[]>(new ListReusableCreditorRequest(officeId))
  }

}
