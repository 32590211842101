import {CategoryFilter} from "../models/account-details-filter.model";
import {TransactionsForAccountBrowseRequest} from "../models";

export class RequestParamsHelper {

  private constructor() {
  }

  public static buildAccountDetailsFiltersRequestBody(transactionsForAccountBrowseRequest: TransactionsForAccountBrowseRequest, size?: number): any {
    const filters = transactionsForAccountBrowseRequest.filters;
    const body: any = {
      excludeOutgoing: filters.excludeOutgoing,
      excludeIncoming: filters.excludeIncoming,
      counterpartName: filters.counterpartName || null,
      counterpartReference: filters.counterpartReference || null,
      remittanceInformation: RequestParamsHelper.sanitizeRemittanceInformation(filters.remittanceInformation),
      description: filters.description || null,
      dateFrom: filters.dateFrom || null,
      dateTo: filters.dateTo || null,
      sortOrder: transactionsForAccountBrowseRequest.sortDirection,
      sortField: transactionsForAccountBrowseRequest.sortColumn,
      categories: RequestParamsHelper.buildCategoriesBody(transactionsForAccountBrowseRequest.filters.categories),
      uncategorized: transactionsForAccountBrowseRequest.filters.uncategorized,
      amountMin: transactionsForAccountBrowseRequest.filters.amountMin,
      amountMax: transactionsForAccountBrowseRequest.filters.amountMax,
      tagIds: transactionsForAccountBrowseRequest.filters.tagIds.length > 0 ? transactionsForAccountBrowseRequest.filters.tagIds : null,
    }

    if (size) {
      body.page = +transactionsForAccountBrowseRequest.currentPage;
      body.size = size;
    }

    return body;
  }

  private static sanitizeRemittanceInformation(remittanceInfo: string|null): string|null {
    if (!remittanceInfo) {
      return null;
    }

    return remittanceInfo?.replace(/(^\++|\++$)/gm, '');
  }

  private static buildCategoriesBody(categoryFilters: CategoryFilter[]): CategoryRequest[]|null {
    if (!categoryFilters || categoryFilters.length === 0) {
      return null;
    }

    const categories: CategoryRequest[] = [];

    for (let filter of categoryFilters) {
      if (!filter.categoryName.includes('_')) {
        categories.push({mainCategoryName: filter.categoryName, subCategoryName: null});
      } else {
        const parts = filter.categoryName.split('_');
        categories.push({mainCategoryName: parts[0], subCategoryName: parts[1]});
      }
    }

    return categories;
  }
}

export interface CategoryRequest {
  mainCategoryName: string;
  subCategoryName: string | null;
}
