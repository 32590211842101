// Angular
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
// app
import { routes } from 'src/app/_nav';
// local
import { TokenService } from '../token/token.service';

@Injectable({
  providedIn: 'root',
})
export class GuestGuardService implements CanActivate {
  constructor(private router: Router, private tokenService: TokenService) {}

  canActivate(): boolean {
    const bearerToken = this.tokenService.getBearerToken();

    if (bearerToken) {
      this.router.navigate([routes.DASHBOARD]).then();
      return false;
    }

    return true;
  }
}
