import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormControl} from '@angular/forms';

@Component({
  selector: 'app-amount-input',
  templateUrl: './amount-input.component.html',
  styleUrls: ['./amount-input.component.scss'],
})
export class AmountInputComponent implements OnInit {

  @Input() control!: FormControl;
  @Input() controlId: string = '';
  @Input() icon: string | undefined; // allow for optional icon
  @Input() min: number | undefined; // allow for optional min number value input limit
  @Input() max: number | undefined; // allow for optional max number value input limit
  @Input() clearable: boolean = false;

  @Output() blur = new EventEmitter<void>();

  private touched: boolean = false;
  private editing: boolean = false;

  constructor() { }

  ngOnInit(): void {
    // don't put validators here cause that will trigger Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError
    let blockUpdates = false; // prevents loops on changing input
    this.restrictNumericInput(blockUpdates);
  }

  private restrictNumericInput(blockUpdates: boolean) {
    // reference the right control from FormArray
    // register for changes to limit input options
    this.control?.valueChanges.subscribe(value => {
      if (blockUpdates) return;
      blockUpdates = true;
      this.control?.patchValue(this.filterContent(value));
      blockUpdates = false;
    });
  }

  filterContent(value: string): string {
    let i = 0,
      filtered: string = value?.toString().replace(',', '.') // while typing replace ',' by '.'
    filtered = filtered?.toString().replace(/\./g, m => !i++ ? m : '') // only allow a single '.'
    filtered = filtered?.toString().replace(/[^0-9\\.,\/]/g, '') // numeric input only and '.' and ','
    // also take into account max value here if applicable
    if (this.max && Number(value) > this.max) {
      return this.max.toString();
    }
    if (this.min && Number(value) < this.min) {
      return this.min.toString();
    }
    return filtered;
  }

  clearInput(): void {
    this.control.setValue(undefined);
  }

  onBlur(): void {
    this.editing = false;
    this.touched = this.control?.value;
    this.blur.emit();
  }

  onInput(): void {
    this.editing = true;
  }

  isEditing(): boolean {
    return this.editing;
  }

  isValid(): boolean {
    return this.control?.valid || this.control?.value === null
  }

  isInputSet(): boolean {
    return this.touched && this.control?.value
  }

}
