// local
import { ClientType } from './client.model';

export interface User {
  id: string;
  fullName: string;
  officeName: string;
  hasOfficeLogo: boolean;
  languageCode: LanguageCode;
  supportedClientTypes: ClientType[];
}

export enum LanguageCode {
  EN = 'en',
  FR = 'fr',
  NL = 'nl',
}
