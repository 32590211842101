export namespace TransactionsExport {
  export enum Type {
    PDF  = 'PDF',
    XLSX = 'XLSX'
  }

  export function label(type: Type): string {
    switch (type) {
      case Type.PDF:  return 'PDF';
      case Type.XLSX: return 'Excel';
      default:        return 'PDF';
    }
  }

  export function mimeType(type: Type): string {
    switch (type) {
      case Type.PDF:  return 'application/pdf';
      case Type.XLSX: return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      default:        return 'application/pdf';
    }
  }

  export function fileExtension(type: Type): string {
    switch (type) {
      case Type.PDF:  return 'pdf';
      case Type.XLSX: return 'xlsx';
      default:        return 'pdf';
    }
  }
}
