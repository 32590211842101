// Angular
import { Pipe, PipeTransform } from '@angular/core';
// shared
import { BankAccount } from 'src/app/shared';

@Pipe({ name: 'showReauthorizeBankAccount' })
export class ShowReauthorizeBankAccountPipe implements PipeTransform {
  transform(bankAccount: BankAccount): boolean {
    if (!bankAccount.expiresAt) {
      return false;
    }

    const date = new Date(bankAccount.expiresAt);
    const currentDate = new Date();

    if (date) {
      let days = Math.floor((date.getTime() - currentDate.getTime()) / 1000 / 60 / 60 / 24);

      return days <= 10;
    }

    return false;
  }
}
