// core
import {APIRequest, Method} from 'src/app/core/models';
import {ClientType} from "../../../shared";

export class ClientBankAccountTypesRequest implements APIRequest {
  method: Method = Method.GET;
  path: string;

  constructor(clientType: ClientType) {
    this.path = '/clients/client-types/' + clientType + '/bank-account-types';
  }
}
