import {APIRequest, Method} from "../../../core/models";
import {KeyValue} from "@angular/common";
import {AppSettings, SortOrder} from "../../../shared";
import {PaymentBrowseRequest, PaymentStatus} from "../models/payment.model";

export class CountQueuedPaymentsRequest implements APIRequest {

  method: Method = Method.GET;
  path: string = '/payments';
  requestParams: KeyValue<string, string>[];

  constructor() {
    this.requestParams = [
      { key: 'page', value: '1' },
      { key: 'size', value: '1' },
      { key: 'sortOrder', value: SortOrder.ASC},
      { key: 'sortField', value: 'executionDate'},
      { key: 'status', value: PaymentStatus.NOT_SUBMITTED }
    ];
  }
}
