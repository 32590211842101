// core
import { APIRequest, Method } from 'src/app/core/models';

export class AvailableReportTypesRequest implements APIRequest {
  method: Method = Method.GET;
  path: string;

  constructor(clientId: string) {
    this.path = '/clients/' + clientId + '/available-report-types';
  }
}
