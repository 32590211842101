export enum routes {
  // LOGIN
  LOGIN = '/login/',

  // Dashboard
  DASHBOARD = '/dashboard/',

  // Clients
  CLIENTS = '/clients/',
  CLIENTS_ADD = '/clients/add/',
  CLIENTS_UPDATE = '/clients/update/',
  CLIENTS_ARCHIVED = '/clients/archived/',

  // Accounts
  ACCOUNTS = '/accounts/',
  ACCOUNTS_ADD = '/accounts/add/',
  ACCOUNTS_IMPORT = '/accounts/import/',
  ACCOUNTS_REAUTHORIZE = '/accounts/reauthorize/',
  ACCOUNTS_UPDATE = '/accounts/update/',
  ACCOUNTS_ARCHIVED = '/accounts/archived/',

  // Inbox
  INBOX = '/inbox/',

  // Settings
  PROFILE = '/configuration/profile/',
  OFFICE_CONFIGURATION = '/configuration/office/',
  OFFICE_CONFIGURATION_TAB_USERS = '/configuration/office#USERS',
  OFFICE_CONFIGURATION_TAB_ISABEL = '/configuration/office#ISABEL_CONNECT',
  USERS_ADD = '/configuration/users/add/',
  USERS_DETAIL = '/configuration/users/',
  BULK_ACTIONS = '/configuration/bulk-actions/',

  // Isabel
  ISABEL_INIT = '/isabel/init/', // Step 2 isabel
  ISABEL_AUTH = '/isabel/auth/', // step 5 isabel

  PAYMENTS = '/payments'
}
