import { SimpleClient } from './simple-client';

export interface BankAccount {
  id: string;
  client?: SimpleClient;
  currency: string;
  currentBalance: number;
  dateCreated: string;
  expired: boolean;
  expiring: boolean;
  expiresAt: string;
  formattedCurrentBalance: string;
  iban: string;
  label: string;
  type: BankAccountOwnerType;
  settings: BankAccountSettings;
  restorable: boolean;
  archivedAt: string;
  bankAccountType: BankAccountType;
  financialInstitution: SimpleFinancialInstitution;
  supportPayments: boolean;
  apiProduct?: ApiProduct;
  synchronizedAt: string;
  latestSynchronizationStatus?: BankAccountSyncStatus;
  /* xs2aDisabled
     - null for non-XS2A accounts
     - false for XS2A accounts at a FI that has not moved to Isabel
     - true for XS2A accounts at a FI that has moved to Isabel */
  xs2aDisabled?: boolean;
  unlinked: boolean;
  position: number;
}

export enum BankAccountOwnerType {
  OFFICE = 'OFFICE',
  CLIENT = 'CLIENT',
}

export interface BankAccountSettings {
  showBalanceToClient: boolean;
  showBalanceToOffice: boolean;
  showIncomingTransactionsToClient: boolean;
  showOutgoingTransactionsToClient: boolean;
  showTransactionsToOffice: boolean;
}

export interface UpdateIban {
  iban: string;
  suggestedAccounts?: BankAccount[];
  ibanInvalid?: boolean
}

export enum BankAccountType {
  BASE = 'BASE',
  RESERVE = 'RESERVE',
  SAVINGS = 'SAVINGS',
  ALLOWANCE = 'ALLOWANCE',
  VAPH = 'VAPH',
  SECURITY = 'SECURITY',
}

export interface BasicBankAccount {
  holderName: string;
  iban: string;
  label: string;
}

export interface SimpleFinancialInstitution {
  name: string;
  logoUrl: string
}

export interface SimpleBankAccount {
  id: string;
  clientId: string;
  iban: string;
  label: string;
  holdername: string;
  description: string;
  position: number;
  type: BankAccountType;
  formattedCurrentBalance: string;
  currency: string;
  financialInstitution: SimpleFinancialInstitution;
}

export enum ApiProduct {
  ISABEL = "ISABEL",
  XS2A = "XS2A",
}

export interface BankAccountSyncStatus {
  status: "success" | "error";
  errorCodes: [string];
}

export enum BankAccountStatus {
  ACTIVE,
  ARCHIVED,
  AUTH_INVALID,
  TECHNICAL_FAILURE,
  MOVED_TO_ISABEL_ACTIVE,
  MOVED_TO_ISABEL_INACTIVE,
  EXPIRING,
  NOT_FOUND_IN_ISABEL,
}
