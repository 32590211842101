import { ApiProduct, BankAccount, BankAccountStatus } from '../../models/bank-account.model';

export function getBankAccountStatus(account: BankAccount): BankAccountStatus | null {
  if (account.archivedAt) {
    return BankAccountStatus.ARCHIVED;
  }
  if (account.apiProduct === ApiProduct.XS2A) {
    if (account.expired || account.latestSynchronizationStatus?.errorCodes?.some(error => error === "authorizationExpired" || error === "authorizationInvalid" || error === "authorizationRevoked")) {
      return account.xs2aDisabled ? BankAccountStatus.MOVED_TO_ISABEL_INACTIVE : BankAccountStatus.AUTH_INVALID;
    }
    if (account.expiring) {
      return account.xs2aDisabled ? BankAccountStatus.MOVED_TO_ISABEL_ACTIVE : BankAccountStatus.EXPIRING;
    }
    if (account.xs2aDisabled) {
      return BankAccountStatus.MOVED_TO_ISABEL_ACTIVE;
    }
    if (account.latestSynchronizationStatus?.errorCodes?.includes("technicalFailure")) {
      return BankAccountStatus.TECHNICAL_FAILURE;
    }
  }
  if (account.apiProduct === ApiProduct.ISABEL) {
    if (account.latestSynchronizationStatus?.errorCodes?.includes("notFound")) {
      return BankAccountStatus.NOT_FOUND_IN_ISABEL;
    }
  }
  return BankAccountStatus.ACTIVE;
}
