// Angular
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-shared-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent {
  @Input() title: string | undefined;
  @Input() subtitle: string | undefined;
  @Input() color: string = '#1890A7';
  @Input() backgroundColor = 'transparent';
  @Input() full = true;
  @Input() loading = true;
  @Input() size = '18px';

  constructor() {}
}
