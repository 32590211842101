// Angular
import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
// 3rd-party
import {Observable} from 'rxjs';
// environment
import {environment} from 'src/environments/environment';
// local
import {APIRequest, Method} from '../../models';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  baseUrl = `${environment.api.host}`;

  constructor(private httpClient: HttpClient) {
  }

  execute<Type>(request: APIRequest): Observable<Type> {
    let url = this.baseUrl + request.path;
    let params: HttpParams = new HttpParams();
    let responseType: string = 'json';
    let acceptHeader: any;

    if (request.requestParams) {
      request.requestParams.forEach((requestParam, index) => {
        params = params.append(requestParam.key, requestParam.value);
      });
    }

    if (request.responseType) {
      responseType = request.responseType;
    }

    if (request.acceptHeader) {
      acceptHeader = {'Accept': request.acceptHeader};
    }

    switch (request.method) {
      case Method.GET:
        return this.httpClient.get<Type>(url, {
          params: params,
          responseType: responseType as 'json',
          headers: acceptHeader
        });
      case Method.POST:
        return this.httpClient.post<Type>(url, this.sanitizeBody(request.body), {
          params: params,
          responseType: responseType as 'json',
          headers: acceptHeader
        });
      case Method.PUT:
        return this.httpClient.put<Type>(url, this.sanitizeBody(request.body));
      case Method.DELETE:
        return this.httpClient.delete<Type>(url);
      case Method.PATCH:
        return this.httpClient.patch<Type>(url, this.sanitizeBody(request.body));
    }
  }

  private sanitizeBody(body: any): any {
    if (typeof body === 'string') {
      return body.trim();
    }

    this.trimAllPropertiesRecursive(body);

    return body;
  }

  private trimAllPropertiesRecursive(obj: any) {
    if (typeof obj === 'string') {
      obj.trim();
      return;
    }

    for (let prop in obj) {
      if (typeof obj[prop] === 'string') {
        obj[prop] = obj[prop].trim();
      } else {
        this.trimAllPropertiesRecursive(obj[prop]);
      }
    }
  }
}
