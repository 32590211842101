// core
import { APIRequest, Method } from 'src/app/core/models';
import { KeyValue } from '@angular/common';

export class ListFinancialInstitutionsRequest implements APIRequest {
  method: Method = Method.GET;
  path: string = '/financial-institutions';
  requestParams: KeyValue<string, string>[];

  constructor(onlyUsed: boolean) {
    this.requestParams = onlyUsed ? [{ key: 'onlyUsed', value: 'true' }] : [];
  }
}
