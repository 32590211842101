export enum Role {
  OFFICE_USER = 'OFFICE_USER',
  OFFICE_ADMIN = 'OFFICE_ADMIN',
  OFFICE_SUPERVISOR = 'OFFICE_SUPERVISOR',
  COURT_USER = 'COURT_USER',
  SUPER_ADMIN = 'SUPER_ADMIN',
  ADMIN = 'ADMIN',
  DEVELOPER = 'DEVELOPER',
  INTERNAL = 'INTERNAL'
}
