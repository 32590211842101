// core
import { APIRequest, Method } from 'src/app/core/models';

export class RestoreClientRequest implements APIRequest {
  method: Method = Method.PUT;
  path: string;

  constructor(clientId: string) {
    this.path = '/clients/' + clientId + '/restore';
  }
}
