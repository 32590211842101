// core
import { APIRequest, Method } from 'src/app/core/models';

export class OpenNotificationRequest implements APIRequest {
  method: Method = Method.PUT;
  path: string;

  constructor(notificationId: string) {
    this.path = '/notifications/' + notificationId + '/unprocessed';
  }
}
