<app-header
  *ngIf='!loading'
  [navbarBrandRouterLink]="roleChecker.isCourtUser() ? ['/clients'] : ['/dashboard']"
  [fixed]="true"
  [navbarBrandFull]="{ src: 'assets/images/brand/ROOV_business.svg', height: 50, alt: 'ROOV Logo' }"
  [sidebarToggler]="false"
  [asideMenuToggler]="false"
  [mobileSidebarToggler]="false"
  [mobileAsideMenuToggler]="false"
>
  <ul class="nav navbar-nav">
    <li class="nav-item px-3" *ngFor="let route of navRoutes">
      <a class="nav-link" [routerLink]="['/', route]" routerLinkActive="active" (click)="onTabClicked()">
        {{ route.toUpperCase() + '.navTitle' | translate }}
      </a>
      <div class="badge" *ngIf="route === 'clients' && countClientsWithNotifications">
        <a [routerLink]="'/clients'"
           [queryParams]="{ 'onlyUnprocessedNotifications': true }"
           title="{{ 'INBOX.openNotifications' | translate }}">{{ countClientsWithNotifications }}</a>
      </div>
      <div class="badge" *ngIf="route === 'accounts' && (countUnlinkedAccounts || countAccountsWithWarning)">
        <a *ngIf="countUnlinkedAccounts"
           [routerLink]="'/accounts'"
           [queryParams]="{ 'linkedStatus': 'UNLINKED', 'sortColumn': 'label' }"
           title="{{ 'ACCOUNTS.UNLINKED' | translate }}">{{ countUnlinkedAccounts }}</a>
        <a *ngIf="countAccountsWithWarning"
           [routerLink]="'/accounts'"
           [queryParams]="{ 'onlyWithWarning': true, 'sortColumn': 'label' }"
           title="{{ 'ACCOUNTS.withWarning' | translate }}">{{ countAccountsWithWarning }}</a>
      </div>
      <div class="badge" *ngIf="route === 'payments' && countPayments">
        <a [routerLink]="'/payments'"
           [queryParams]="{ 'status': 'NOT_SUBMITTED' }"
           title="{{ 'PAYMENTS.NOT_SUBMITTED' | translate }}">{{ countPayments }}</a>
      </div>
      <div class="badge" *ngIf="route === 'inbox' && countUnprocessedNotifications">
        <a [routerLink]="'/inbox'"
           [queryParams]="{ 'unprocessed': true }"
           title="{{ 'INBOX.openNotifications' | translate }}">{{ countUnprocessedNotifications }}</a>
      </div>
    </li>
  </ul>
  <ul class="nav navbar-nav ml-auto">
    <ng-multiselect-dropdown *ngIf="roleChecker.canFilterAssignees()"
                             [placeholder]="'assigneeFilterPlaceholder' | translate"
                             [settings]="dropdownSettings"
                             [data]="dropdownList"
                             [(ngModel)]="selectedItems"
                             (onDeSelect)='filterAll()'
                             (onDeSelectAll)='filterAll($event)'
                             (onSelect)="filterAll()"
                             (onSelectAll)="filterAll($event)"
    ></ng-multiselect-dropdown>
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a
        class="menu-item"
        data-toggle="dropdown"
        role="button"
        aria-haspopup="true"
        aria-expanded="false"
        dropdownToggle
      >
        <i class="fas fa-bars"></i>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <a class="dropdown-item" (click)="onProfileClicked()">
          <i class="fas fa-id-badge"></i>
          {{ 'NAVBAR.profile' | translate }}
        </a>
        <a class="dropdown-item" *ngIf="roleChecker.canManageOffice()" (click)="onOfficeConfigurationClicked()">
          <i class="fas fa-building"></i>
          {{ 'NAVBAR.office' | translate }}
        </a>
        <ng-container *ngIf="!roleChecker.isCourtUser()">
          <div class="dropdown-divider"></div>
          <a class='dropdown-item' (click)='onBulkActionsClicked()'>
            <i class='fas fa-check-double'></i>
            {{ 'NAVBAR.actions' | translate }}
          </a>
          <a class="dropdown-item" (click)="onArchiveClientsClicked()">
            <i class="fas fa-user"></i>
            {{ 'NAVBAR.archivedClients' | translate }}
          </a>
          <a class="dropdown-item" (click)="onArchiveAccountsClicked()">
            <i class="fas fa-credit-card"></i>
            {{ 'NAVBAR.archivedAccounts' | translate }}
          </a>
        </ng-container>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="{{ 'NAVBAR.helpdeskUrl' | translate }}" target="_blank">
          <i class="fas fa-question-circle"></i>
          {{ 'NAVBAR.helpdesk' | translate }}
          <i class="fas fa-external-link-alt"></i>
        </a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" (click)="onLogout()">
          <i class="fas fa-lock"></i>
          {{ 'NAVBAR.logout' | translate }}
        </a>
      </div>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <main class="main">
    <div class="container-fluid">
      <router-outlet></router-outlet>
    </div>
  </main>
</div>
<app-footer>
  <span class="ml-auto">
    <a href="https://www.roov.app">roov</a>
     &copy; {{year}} Augias corp.
  </span>
</app-footer>
