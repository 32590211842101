// Angular
// core
import {APIRequest, Method} from 'src/app/core/models';
// local
import {TransactionsForAccountBrowseRequest} from '../models';
import {RequestParamsHelper} from "./request-params-helper";
import {TransactionsExport} from '../utils/transactions-export';

export class DownloadTransactionsForAccountRequest implements APIRequest {
  method: Method = Method.POST;
  path: string;
  acceptHeader: string;
  responseType: string = 'blob'
  body: any;

  constructor(bankAccountId: string, transactionsForAccountBrowseRequest: TransactionsForAccountBrowseRequest, type: TransactionsExport.Type) {
    this.path = '/bank-accounts/' + bankAccountId + '/transactions';
    this.acceptHeader = TransactionsExport.mimeType(type);
    this.body = RequestParamsHelper.buildAccountDetailsFiltersRequestBody(transactionsForAccountBrowseRequest);
  }
}
