// core
import { APIRequest, Method } from 'src/app/core/models';

export class DownloadReportRequest implements APIRequest {
  method: Method = Method.GET;
  path: string;
  responseType: string = 'blob';

  constructor(clientId: string, reportId: string) {
    this.path = '/clients/' + clientId + '/reports/' + reportId;
  }
}
