// core
import { APIRequest, Method } from 'src/app/core/models';
// local
import { AiarCreation } from '../models';

export class AiarCreationRequest implements APIRequest {
  method: Method = Method.POST;
  path: string;
  body: AiarCreation;

  constructor(aiarCreation: AiarCreation, forImport: boolean) {
    this.body = aiarCreation;
    this.path = forImport ? '/bank-accounts/aiar-import' : '/bank-accounts/aiar';
  }
}
