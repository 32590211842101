// Angular
import {KeyValue} from '@angular/common';
// core
import {APIRequest, Method} from 'src/app/core/models';
// shared
import {AppSettings} from 'src/app/shared';
// local
import {ClientsBrowseRequest} from '../models';

export class ListClientsRequest implements APIRequest {


  method: Method = Method.GET;
  path: string = '/clients';
  requestParams: KeyValue<string, string>[];

  size: number = AppSettings.LIST_SIZE;

  constructor(clientsBrowseRequest: ClientsBrowseRequest) {
    this.requestParams = [
      { key: 'page', value: clientsBrowseRequest.currentPage.toString() },
      { key: 'size', value: this.size.toString() },
      { key: 'sortOrder', value: clientsBrowseRequest.sortDirection },
      { key: 'sortField', value: clientsBrowseRequest.sortColumn },
    ];

    if (clientsBrowseRequest.query) {
      this.requestParams.push({
        key: 'query',
        value: clientsBrowseRequest.query,
      });
    }

    if (clientsBrowseRequest.onlyUnprocessedNotifications) {
      this.requestParams.push({
        key: 'onlyUnprocessedNotifications',
        value: clientsBrowseRequest.onlyUnprocessedNotifications.toString(),
      });
    }

    if (clientsBrowseRequest.clientType) {
      this.requestParams.push({
        key: 'clientType',
        value: clientsBrowseRequest.clientType,
      });
    }

    if (clientsBrowseRequest.office) {
      this.requestParams.push({
        key: 'office',
        value: clientsBrowseRequest.office,
      });
    }
  }
}
