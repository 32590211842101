// Angular
import { NgModule } from '@angular/core';
import { ActivatedRouteSnapshot, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';
// core
import { AuthenticationGuardService, GuestGuardService } from './core/services';
import { DefaultLayoutComponent, PageNotFoundComponent } from './core/components';
import { Role } from './core/models/role.enum';

const appRoutes: Routes = [
  {
    path: 'login',
    canActivate: [GuestGuardService],
    loadChildren: () => import('./modules/login/login.module').then((m) => m.LoginModule),
  },
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
      },
      {
        path: 'dashboard',
        canActivate: [AuthenticationGuardService],
        loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule),
        data: {
          role: Role.OFFICE_USER,
          redirectUri: '/clients'
        }
      },
      {
        path: 'clients',
        canActivate: [AuthenticationGuardService],
        loadChildren: () => import('./modules/clients/clients.module').then((m) => m.ClientsModule),
      },
      {
        path: 'accounts',
        canActivate: [AuthenticationGuardService],
        loadChildren: () => import('./modules/accounts/accounts.module').then((m) => m.AccountsModule),
      },
      {
        path: 'inbox',
        canActivate: [AuthenticationGuardService],
        loadChildren: () => import('./modules/inbox/inbox.module').then((m) => m.InboxModule),
      },
      {
        path: 'configuration',
        canActivate: [AuthenticationGuardService],
        loadChildren: () => import('./modules/configuration/configuration.module').then((m) => m.ConfigurationModule),
      },
      {
        path: 'isabel',
        canActivate: [AuthenticationGuardService],
        loadChildren: () => import('./modules/isabel/isabel.module').then((m) => m.IsabelModule),
      },
      {
        path: 'payments',
        canActivate: [AuthenticationGuardService],
        loadChildren: () => import('./modules/payments/payments.module').then((m) => m.PaymentsModule),
      },
      {
        path: '**',
        redirectTo: '/not-found',
        pathMatch: 'full',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
