// Angular
import { Injectable } from '@angular/core';
// 3rd-party
import { Observable } from 'rxjs';
// core
import { ApiService } from 'src/app/core/services';
// shared
import { Notification, NotificationsBrowseRequest, NotificationsResult } from 'src/app/shared';
import {
  CloseNotificationRequest,
  ListNotificationsRequest,
  OpenNotificationRequest,
  UnprocessedCountNotificationsRequest
} from '../api-requests';

@Injectable({
  providedIn: 'root',
})
export class NotificationsService {
  constructor(private apiService: ApiService) {}

  getNotifications(notificationsBrowseRequest: NotificationsBrowseRequest): Observable<NotificationsResult> {
    return this.apiService.execute<NotificationsResult>(new ListNotificationsRequest(notificationsBrowseRequest));
  }

  countUnprocessedNotifications(): Observable<number> {
    return this.apiService.execute<number>(new UnprocessedCountNotificationsRequest());
  }

  closeNotification(notificationId: string): Observable<Notification> {
    return this.apiService.execute<Notification>(new CloseNotificationRequest(notificationId));
  }

  openNotification(notificationId: string): Observable<Notification> {
    return this.apiService.execute<Notification>(new OpenNotificationRequest(notificationId));
  }
}
