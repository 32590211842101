// shared
import {BrowseRequest, PagingResponse} from 'src/app/shared';
import {SortRequest} from "../../../shared/models/sort-request.model";
import {AccountDetailsFilter} from "./account-details-filter.model";
import {Category} from "./transaction-category.model";
import {Tag} from './transaction-tag.model';

export interface TransactionsResult extends PagingResponse<Transaction> {
  results: Transaction[];
}

export interface Transaction {
  id: string;
  amount: number;
  counterpartName?: string;
  counterpartReference?: string;
  currency: string;
  description?: string;
  executionDate: string;
  formattedAmount: string;
  remittanceInformation?: string;
  remittanceInformationType?: string;
  category: Category;
  intraday?: Boolean;
  creditorId?: string;
  tags: Tag[];
}

export interface TransactionsForAccountBrowseRequest extends BrowseRequest, SortRequest {
  filters: AccountDetailsFilter;
}
