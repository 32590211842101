// core
import { APIRequest, Method } from 'src/app/core/models';
// local
import { UserDetailDTO } from '../models';

export class UpdateUserRequest implements APIRequest {
  method: Method = Method.PUT;
  path: string;
  body: UserDetailDTO;

  constructor(userDetailDTO: UserDetailDTO, userId: string) {
    this.path = '/office/users/' + userId;
    this.body = userDetailDTO;
  }
}
