// Angular
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
// 3rd-party
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
// app
import { routes } from 'src/app/_nav';
// shared
import { AppSettings } from 'src/app/shared';
// local
import { TokenService } from '../token/token.service';
import { UserStoreService } from '../user/user-store.service';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {
  private unAuthorizedCalls: string[] = ['/login', '/assets/i18n/*'];

  constructor(private router: Router, private tokenService: TokenService, private userStoreService: UserStoreService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    request = this.addBearerTokenToRequestHeaders(request);

    if (!request.params.get(AppSettings.EXCLUDE_ASSIGNEES_FROM_API) ?? false) {
      request = this.addAssigneeParams(request);
    }

    return next.handle(request).pipe(
      tap(
        (event: HttpEvent<any>) => {
          this.setBearerToken(event, request);
          return event;
        },
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401 && request.url.indexOf('/auth/password') === -1) {
              this.tokenService.removeToken();
              this.router.navigate([routes.LOGIN]).then();
            }
          }
        }
      )
    );
  }

  // Get bearer token when logging in & set in tokenService.
  private setBearerToken(event: HttpEvent<any>, request: HttpRequest<any>): void {
    if (event instanceof HttpResponse && request.url.indexOf('/login') > -1 && event.body && event.body.token) {
      this.tokenService.setBearerToken(event.body.token);
    }
  }

  private addBearerTokenToRequestHeaders(request: HttpRequest<any>): HttpRequest<any> {
    // Check if url needs to be authorized
    if (request.url && !this.unAuthorizedCalls.some((call) => new RegExp(call).test(request.url))) {
      const bearerToken = this.tokenService.getBearerToken();

      // Set bearer token in header if no token route to login
      if (bearerToken) {
        request = request.clone({
          setHeaders: {
            Authorization: `bearer ${bearerToken}`,
          },
        });
      } else {
        this.router.navigate([routes.LOGIN]).then();
      }
    }

    return request;
  }

  private addAssigneeParams(request: HttpRequest<any>): HttpRequest<any> {
    request = request.clone({
      setParams: {
        assignees: this.userStoreService.getSelectedAssignees().toString(),
      },
    });

    return request;
  }
}
