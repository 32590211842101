// Angular
import {Injectable} from '@angular/core';
// 3rd-party
import {BehaviorSubject, Observable} from 'rxjs';
import {isEqual} from 'lodash';
// shared
import {deepCopy, SortOrder} from 'src/app/shared';
// local
import {ClientDocumentsBrowseRequest, ClientReportsBrowseRequest, ClientsBrowseRequest} from '../models';

@Injectable({ providedIn: 'root' })
export class ClientsStoreService {
  private clientsBrowseInitialValue: ClientsBrowseRequest = {
    currentPage: 1,
    query: '',
    sortColumn: 'lastName',
    sortDirection: SortOrder.ASC
  };

  private archivedClientsBrowseInitialValue: ClientsBrowseRequest = {
    currentPage: 1,
    query: '',
    sortColumn: 'lastName',
    sortDirection: SortOrder.ASC
  };

  private clientReportsBrowseInitialValue: ClientReportsBrowseRequest = {
    currentPage: 1,
  };

  private clientDocumentsBrowseInitialValue: ClientDocumentsBrowseRequest = {
    currentPage: 1,
    query: '',
    includeHiddenDocuments: false
  };

  private _currentClientsBrowseRequest: BehaviorSubject<ClientsBrowseRequest> =
    new BehaviorSubject<ClientsBrowseRequest>(deepCopy(this.clientsBrowseInitialValue));

  private _currentArchivedClientsBrowseRequest: BehaviorSubject<ClientsBrowseRequest> =
    new BehaviorSubject<ClientsBrowseRequest>(deepCopy(this.archivedClientsBrowseInitialValue));

  private _currentClientReportsBrowseRequest: BehaviorSubject<ClientReportsBrowseRequest> =
    new BehaviorSubject<ClientReportsBrowseRequest>(deepCopy(this.clientReportsBrowseInitialValue));

  private _currentClientDocumentsBrowseRequest: BehaviorSubject<ClientDocumentsBrowseRequest> =
    new BehaviorSubject<ClientDocumentsBrowseRequest>(deepCopy(this.clientDocumentsBrowseInitialValue));

  public readonly currentClientsBrowseRequest: Observable<ClientsBrowseRequest> =
    this._currentClientsBrowseRequest.asObservable();

  public readonly currentArchivedClientsBrowseRequest: Observable<ClientsBrowseRequest> =
    this._currentArchivedClientsBrowseRequest.asObservable();

  public readonly currentClientReportsBrowseRequest: Observable<ClientReportsBrowseRequest> =
    this._currentClientReportsBrowseRequest.asObservable();

  public readonly currentClientDocumentsBrowseRequest: Observable<ClientDocumentsBrowseRequest> =
    this._currentClientDocumentsBrowseRequest.asObservable();

  updateCurrentClientsBrowseRequest(clientsBrowseRequest: ClientsBrowseRequest): void {
    if (!isEqual(clientsBrowseRequest, this._currentClientsBrowseRequest.getValue())) {
      this._currentClientsBrowseRequest.next(clientsBrowseRequest);
    }
  }

  updateCurrentArchivedClientsBrowseRequest(clientsBrowseRequest: ClientsBrowseRequest) {
    this._currentArchivedClientsBrowseRequest.next(clientsBrowseRequest);
  }

  updateCurrentClientReportsBrowseRequest(clientReportsBrowseRequest: ClientReportsBrowseRequest): void {
    this._currentClientReportsBrowseRequest.next(clientReportsBrowseRequest);
  }

  updateCurrentClientDocumentsBrowseRequest(clientDocumentsBrowseRequest: ClientDocumentsBrowseRequest): void {
    this._currentClientDocumentsBrowseRequest.next(clientDocumentsBrowseRequest);
  }

  resetPageClientsBrowseRequest(): void {
    const currentRequest = this._currentClientsBrowseRequest.getValue();
    currentRequest.currentPage = 1;
    this._currentClientsBrowseRequest.next(currentRequest);
  }

  clearStore(): void {
    this._currentClientsBrowseRequest.next(deepCopy(this.clientsBrowseInitialValue));
    this.clearArchivedStore();
    this.clearDetailStore();
  }

  clearArchivedStore(): void {
    this._currentArchivedClientsBrowseRequest.next(deepCopy(this.archivedClientsBrowseInitialValue));
  }

  clearDetailStore(): void {
    this._currentClientReportsBrowseRequest.next(deepCopy(this.clientReportsBrowseInitialValue));
    this._currentClientDocumentsBrowseRequest.next(deepCopy(this.clientDocumentsBrowseInitialValue));
  }
}
