// core
import { APIRequest, Method } from 'src/app/core/models';

export class AddDocumentsRequest implements APIRequest {
  method: Method = Method.POST;
  path: string;
  body: FormData;

  constructor(clientId: string, formData: FormData) {
    this.path = '/clients/' + clientId + '/documents';
    this.body = formData;
  }
}
