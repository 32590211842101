import {APIRequest, Method} from "../../../core/models";
import {KeyValue} from "@angular/common";
import {AppSettings} from "../../../shared";
import {EditPayment, Payment, PaymentBrowseRequest} from "../models/payment.model";

export class SubmitPaymentsRequest implements APIRequest {

  method: Method = Method.POST;
  path: string = '/payments/initiations';
  body: string[];

  constructor(payments: Payment[]) {
    this.body = payments
      .filter(payment => payment.id)
      .map(payment => payment.id!);
  }
}
