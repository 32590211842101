// Angular
import { Injectable } from '@angular/core';
// 3rd-party
import { Observable, of } from 'rxjs';
// core
import { ApiService } from 'src/app/core/services';
// local
import { ActionType, BulkAction, BulkActionStepType } from '../models';
import { SendWelcomeMailToAllRequest } from '../api-requests';

@Injectable()
export class BulkActionsService {
  constructor(private apiService: ApiService) {}

  getActions(): Observable<BulkAction[]> {
    return of([{ type: ActionType.SEND_EMAIL, icon: 'fa-envelope', label: 'inviteForClientApp' }]);
  }

  getStepsForAction(action: ActionType): Observable<any> {
    return of({
      totalSteps: 3,
      steps: [
        {
          type: BulkActionStepType.BASIC,
          title: 'inviteForClientApp',
          label: 'inviteForClientAppPage2Label',
          hasSendButton: true,
        },
        {
          type: BulkActionStepType.BASIC,
          title: 'inviteForClientApp',
          label: 'inviteForClientAppCompleteLabel',
          hasCompleteButton: true,
        },
      ],
    });
  }

  sendEmailToAllClients(): Observable<any> {
    return this.apiService.execute<any>(new SendWelcomeMailToAllRequest());
  }
}
