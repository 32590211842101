// Angular
import {Injectable} from '@angular/core';
// 3rd-party
import {BehaviorSubject, Observable} from 'rxjs';
import {isEqual} from 'lodash';
// shared
import {deepCopy, NotificationsBrowseRequest} from 'src/app/shared';

@Injectable({
  providedIn: 'root',
})
export class InboxNotificationsStoreService {
  private notificationsBrowseInitialValue: NotificationsBrowseRequest = {
    currentPage: 1,
  };

  private clientNotificationsBrowseInitialValue: NotificationsBrowseRequest = {
    currentPage: 1,
  };

  private _currentNotificationsBrowseRequest: BehaviorSubject<NotificationsBrowseRequest> =
    new BehaviorSubject<NotificationsBrowseRequest>(deepCopy(this.notificationsBrowseInitialValue));

  private _currentClientNotificationsBrowseRequest: BehaviorSubject<NotificationsBrowseRequest> =
    new BehaviorSubject<NotificationsBrowseRequest>(deepCopy(this.clientNotificationsBrowseInitialValue));

  public readonly currentNotificationsBrowseRequest: Observable<NotificationsBrowseRequest> =
    this._currentNotificationsBrowseRequest.asObservable();

  public readonly currentClientNotificationsBrowseRequest: Observable<NotificationsBrowseRequest> =
    this._currentClientNotificationsBrowseRequest.asObservable();

  updateCurrentNotificationsBrowseRequest(notificationsBrowseRequest: NotificationsBrowseRequest): void {
    if (!isEqual(notificationsBrowseRequest, this._currentNotificationsBrowseRequest.getValue())) {
      this._currentNotificationsBrowseRequest.next(notificationsBrowseRequest);
    }
  }

  updateCurrentClientNotificationsBrowseRequest(notificationsBrowseRequest: NotificationsBrowseRequest): void {
    this._currentClientNotificationsBrowseRequest.next(notificationsBrowseRequest);
  }

  resetPageNotificationsBrowseRequest(): void {
    const currentRequest = this._currentNotificationsBrowseRequest.getValue();
    currentRequest.currentPage = 1;
    this._currentNotificationsBrowseRequest.next(currentRequest);
  }

  clearStore(): void {
    this._currentNotificationsBrowseRequest.next(deepCopy(this.notificationsBrowseInitialValue));
    this.clearDetailStore();
  }

  clearDetailStore(): void {
    this._currentClientNotificationsBrowseRequest.next(deepCopy(this.clientNotificationsBrowseInitialValue));
  }
}
