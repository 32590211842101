// Angular
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-query-filter',
  templateUrl: './query-filter.component.html',
  styleUrls: ['./query-filter.component.scss'],
})
export class QueryFilterComponent {
  @Input() isIban: boolean = false;
  @Input() placeholder: string = '';
  @Input() query: string = '';
  @Input() disabled: boolean = false;

  @Output() queryChanged: EventEmitter<string> = new EventEmitter<string>();

  onQueryChanged(event: string) {
    this.queryChanged.emit(event);
  }
}
