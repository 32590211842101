<thead [class]="classNameFromContext">
<tr>
  <th *ngIf="showSelectAll" class="select">
    <input *ngIf="selectAllVisible"
           type="checkbox"
           [checked]="selectAllChecked"
           [indeterminate]="selectAllIndeterminate"
           (change)="handleSelectAllChange()"/>
  </th>
  <th *ngFor="let column of columns" [class]="column.name | camelToKebab"
      [ngClass]="{'sortable': column.sortable}"
      (click)="handleSortingChange(column)">
    <ng-container *ngIf="!column.hideLabel">{{ context + '.' + column.name | translate }}</ng-container>

    <i class="fas"
       *ngIf="column.sortingState.active"
       [ngClass]="{
            'fa-arrow-down': column.sortingState.sortDirection === SortOrderEnum.ASC,
            'fa-arrow-up': column.sortingState.sortDirection === SortOrderEnum.DESC
         }"
    ></i>
  </th>
  <th *ngIf="showDetailBtn" class="detail"></th>
</tr>
</thead>
