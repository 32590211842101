// Angular
import {KeyValue} from '@angular/common';
// core
import {APIRequest, Method} from 'src/app/core/models';
import {AppSettings, SortOrder} from '../../../shared';
import {ClientReportsBrowseRequest} from '../models';
// shared
// local

export class ListClientReportsRequest implements APIRequest {
  method: Method = Method.GET;
  path: string;
  requestParams: KeyValue<string, string>[];

  size: number = AppSettings.LIST_SIZE;
  sort: SortOrder = SortOrder.DESC;
  sortField: string = 'createdAt';

  constructor(clientId: string, clientReportsBrowseRequest: ClientReportsBrowseRequest) {
    this.path = '/clients/' + clientId + '/reports/';

    this.requestParams = [
      { key: 'page', value: clientReportsBrowseRequest.currentPage.toString() },
      { key: 'size', value: this.size.toString() },
      { key: 'sortOrder', value: this.sort },
      { key: 'sortField', value: this.sortField },
    ];
  }
}
