import {Directive, HostListener, Optional} from '@angular/core';
import {NgControl} from "@angular/forms";

@Directive({
  selector: 'input[trimInput],textarea[trimInput]'
})
export class TrimInputDirective {

  constructor(@Optional() private ngControl: NgControl) {
  }

  ngOnInit(): void {
    if (!this.ngControl) {
      console.warn('TrimInputDirective required ngModel, formControl or formControlName.');
      return;
    }

  }

  @HostListener('blur', [
    '$event.target',
    '$event.target.value',
  ])
  onBlur(el: any, value: string): void {
    // Check if we can trim & if it will have any effect
    if ('function' !== typeof value.trim || value.trim() === value) {
      return;
    }

    // Dispatch events so the form works as normal, and our values are updated in the components
    el.value = value.trim();
    const event = document.createEvent('Event');
    event.initEvent('input', false, false);
    el.dispatchEvent(event);

    const eventNew = document.createEvent('Event');
    eventNew.initEvent('blur', false, false);
    el.dispatchEvent(eventNew);
  }

}
