import {APIRequest, Method} from "../../../core/models";

export class ListReusableCreditorRequest implements APIRequest {
  method: Method = Method.GET;
  path: string;

  constructor(officeId: string) {
    this.path = `/offices/${officeId}/creditors`;
  }
}
