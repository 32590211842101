// Angular
import {KeyValue} from '@angular/common';
// core
import {APIRequest, Method} from 'src/app/core/models';
// shared
import {AppSettings, SortOrder} from 'src/app/shared';
// local
import {ClientDocumentsBrowseRequest} from '../models';

export class BrowseDocumentsRequest implements APIRequest {
  method: Method = Method.GET;
  path: string;
  requestParams: KeyValue<string, string>[];

  size: number = AppSettings.LIST_SIZE;
  sort: SortOrder = SortOrder.DESC;
  sortField: string = 'createdAt';

  constructor(clientId: string, documentsBrowseRequest: ClientDocumentsBrowseRequest) {
    this.path = '/clients/' + clientId + '/documents';

    this.requestParams = [
      { key: 'page', value: documentsBrowseRequest.currentPage.toString() },
      { key: 'size', value: this.size.toString() },
      { key: 'sortField', value: this.sortField },
      { key: 'sortOrder', value: this.sort },
    ];

    // by default hide hidden documents, so also when undefined
    // no param will include both hidden and visible documents
    if (!documentsBrowseRequest.includeHiddenDocuments) {
      this.requestParams.push({
        key: 'hiddenDocuments',
        value: 'false',
      });
    }

    if (documentsBrowseRequest.query) {
      this.requestParams.push({
        key: 'query',
        value: documentsBrowseRequest.query,
      });
    }

    if (documentsBrowseRequest.shared !== undefined) {
      this.requestParams.push({
        key: 'shared',
        value: documentsBrowseRequest.shared ? 'true' : 'false',
      });
    }

    if (documentsBrowseRequest.ownDocument != undefined) {
      this.requestParams.push({
        key: 'ownDocument',
        value: documentsBrowseRequest.ownDocument ? 'true' : 'false',
      });
    }
  }
}
