import {APIRequest, Method} from "../../../core/models";
import {Document} from "../models";

export class ShareDocumentRequest implements APIRequest {
  method: Method = Method.POST;
  path: string;
  body: any;

  constructor(clientId: string, document: Document) {
    this.path = '/clients/' + clientId + '/documents/' + document.id + '/share-requests';
    this.body = {};
  }
}

export class DeleteShareDocumentRequest implements APIRequest {
  method: Method = Method.DELETE;
  path: string;
  body: any;

  constructor(clientId: string, document: Document) {
    this.path = '/clients/' + clientId + '/documents/' + document.id + '/share-requests';
    this.body = {};
  }
}
