// angular
import { Injectable } from '@angular/core';
// shared
import { ClientType } from 'src/app/shared';
// local
import { ClientCharacteristics } from '../models';

@Injectable()
export class ClientCharacteristicsService {
  constructor() {}

  static defaultCharacteristics: ClientCharacteristics = {
    canHaveClientBankAccounts: false,
    canHaveOfficeBankAccounts: false,
    canHaveReimbursements: false,
    canHaveReports: false,
    canHaveDebts: false,
    canHaveBalanceAboveNotifications: false,
    canHaveIdentifiers: false,
    canHaveJusticePeace: false,
  };

  static getCharacteristics(clientType: ClientType): ClientCharacteristics {
    switch (clientType) {
      case ClientType.DEBT_MEDIATION:
        return {
          canHaveClientBankAccounts: true,
          canHaveOfficeBankAccounts: true,
          canHaveMultipleClientBankAccounts: true,
          canHaveMultipleOfficeBankAccounts: false,
          canHaveReimbursements: false,
          canHaveReports: true,
          canHaveDebts: true,
          canHaveBalanceAboveNotifications: false,
          canHaveIdentifiers: true,
          canHaveJusticePeace: false,
        };
      case ClientType.FINANCIAL_MANAGEMENT:
        return {
          canHaveClientBankAccounts: true,
          canHaveOfficeBankAccounts: true,
          canHaveMultipleClientBankAccounts: true,
          canHaveMultipleOfficeBankAccounts: true,
          canHaveReimbursements: false,
          canHaveReports: true,
          canHaveDebts: false,
          canHaveBalanceAboveNotifications: true,
          canHaveIdentifiers: true,
          canHaveJusticePeace: true,
        };
      case ClientType.PCSW:
        return {
          canHaveClientBankAccounts: true,
          canHaveOfficeBankAccounts: false,
          canHaveMultipleClientBankAccounts: true,
          canHaveReimbursements: true,
          canHaveReports: false,
          canHaveDebts: false,
          canHaveBalanceAboveNotifications: false,
          canHaveIdentifiers: false,
          canHaveJusticePeace: false,
        };
      case ClientType.BUDGET_GUIDANCE:
        return {
          canHaveClientBankAccounts: false,
          canHaveOfficeBankAccounts: false,
          canHaveReimbursements: false,
          canHaveReports: true,
          canHaveDebts: false,
          canHaveBalanceAboveNotifications: false,
          canHaveIdentifiers: false,
          canHaveJusticePeace: false,
        };
      case ClientType.SOCIAL_RENT:
        return {
          canHaveClientBankAccounts: false,
          canHaveOfficeBankAccounts: false,
          canHaveReimbursements: false,
          canHaveReports: true,
          canHaveDebts: false,
          canHaveBalanceAboveNotifications: false,
          canHaveIdentifiers: false,
          canHaveJusticePeace: false,
        };
      case ClientType.RENT:
        return {
          canHaveClientBankAccounts: false,
          canHaveOfficeBankAccounts: false,
          canHaveReimbursements: false,
          canHaveReports: false,
          canHaveDebts: false,
          canHaveBalanceAboveNotifications: false,
          canHaveIdentifiers: false,
          canHaveJusticePeace: false,
        };
      default:
        return this.defaultCharacteristics;
    }
  }
}
