// Angular
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.scss']
})
export class ConfirmPopupComponent {
  @Input() title: string = '';
  @Input() text: string = '';
  @Input() confirmBtnText: string = '';

  @Output() closePopup: EventEmitter<void> = new EventEmitter<void>();
  @Output() confirmClicked: EventEmitter<void> = new EventEmitter<void>();

  onClosePopup(): void {
    this.closePopup.emit();
  }

  onConfirmClicked(): void {
    this.confirmClicked.emit();
  }
}
