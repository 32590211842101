// core
import { APIRequest, Method } from 'src/app/core/models';
// local
import { UserDetailDTO } from '../models';

export class AddUserRequest implements APIRequest {
  method: Method = Method.POST;
  path: string = '/office/users';
  body: UserDetailDTO;

  constructor(userDetailDTO: UserDetailDTO) {
    this.body = userDetailDTO;
  }
}
