// core
import { APIRequest, Method } from 'src/app/core/models';

export class AiarInfoForBankAccountRequest implements APIRequest {
  method: Method = Method.GET;
  path: string;

  constructor(bankAccountId: string) {
    this.path = `/bank-accounts/${bankAccountId}/aiar-info`;
  }
}
